import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NbDialogRef, NbIconLibraries } from '@nebular/theme';
import { SocialService } from '../../../services/social.service';
import { WorkoutService } from '../../../services/workout.service';

@Component({
  selector: 'ngx-preview-workout-dialog',
  templateUrl: './preview-workout-dialog.component.html',
  styleUrls: ['./preview-workout-dialog.component.scss']
})
export class PreviewWorkoutDialogComponent implements OnInit {
  workout: any;
  loading = true;
  alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  constructor(protected dialogRef: NbDialogRef<PreviewWorkoutDialogComponent>,
              private socialService: SocialService,
              private workoutService: WorkoutService) { }

  ngOnInit(): void {
    this.socialService.getFullPostWorkout(this.workout.Id).subscribe(workout => {
      this.workoutService.getWorkoutDto(workout.Id, false, true).subscribe(workoutDto => {
        this.workout = workoutDto;
        for (var i = 0; i < this.workout.FlattenedTimers.length; i++) {
          this.workout.FlattenedTimers[i].GroupLetter = this.alphabet[i];
        }
        this.loading = false;
      }, err => {
        console.error(err);
        this.loading = false;
      });

    }, err => {
      console.error(err);
      this.loading = false;
    });

  }

  cancel() {
    this.dialogRef.close(null);
  }

  download() {
    this.dialogRef.close(true);
  }

}

