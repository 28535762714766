import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private userId: number;
  private workouts: any[];
  private folders: any[] =  [{
    FolderID: -1,
    FolderName: 'Default Folder',
    Order: -1,
    Workouts: []
  }];
  private baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) {

  }

  public enterRosterInviteCode(code): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/RosterApi/EnterAthleteRosterInviteCode`, {'Code': code, 'UpdatedApp': true});
  }

  public getAvailableSubscriptions(): Observable<any> {
    return this.http.get(this.baseUrl+'/Account/GetAvailableSubscriptions');
  }


  public isCiqActivated(): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/AccountApi/IsCiqActivated`);
  }

  public activateForCiq(code: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/AccountApi/ActivateForCiq?code=${code}`);
  }

  public enterSupporterRosterInviteCode(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/RosterApi/EnterSupporterRosterInviteCode`, data);
  }

  public getFullWorkout(workoutId): any {
    var body = `workoutId=${workoutId}`;
    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http
      .post(this.baseUrl+'/Workout/GetWorkout', body);
  }

  public recordTrainingMax(athleteId, reps, weight, workoutId, exerciseId): any {
    var data = {
      AthleteID: athleteId,
      Reps: reps,
      Weight: weight,
      WorkoutID: workoutId,
      ExerciseID: exerciseId
    }
    return this.http.post(this.baseUrl+'/api/RosterApi/RecordTrainingMax', data);
  }


  public getScoresForTestTemplate(testTemplateId): Observable<any[]> {
    var headers = new Headers();
    return this.http.get<any[]>(this.baseUrl+'/api/RosterApi/GetTestEntriesForPeriod/'+testTemplateId);
  }

  public getCoachAssignedWorkouts(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl+'/api/RosterApi/GetCoachAssignedWorkouts');
  }

  public getAthletesForClass(classId, activeUsersOnly = false): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl+`/api/RosterApi/GetAthletesForClass?classId=${classId}&sort=1&activeUsersOnly=${activeUsersOnly}`);
  }

  public getAthletesForAttendance(id: any): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl+'/api/RosterApi/GetAthletesForAttendance?attendanceId=' + id)
  }

  public getCoachEvents(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl+'/api/RosterApi/GetCoachEvents');
  }
  
  public getCoachPractices(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl+'/api/PracticeApi/GetAgendaPractices');
  }

  public getExerciseNameFromServer(exId): Observable<any> {
    return this.http.get(this.baseUrl+'/api/RosterApi/ExerciseName/'+exId);
  }

  public getCurrentAthlete(): Observable<any[]> {
    var headers = new Headers();
    return this.http.get<any[]>(this.baseUrl+'/api/RosterApi/CurrentAthlete');
  }

  public getRecentAthleteScores(athleteId): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl+'/api/RosterApi/RecentAthleteScores/'+athleteId);
  }

  public getAthleteProfileData(athleteId): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl+'/api/RosterApi/AthleteProfileData/'+athleteId);
  }

  public getCorrelationsForWorkout(workoutId): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl+'/api/RosterApi/CorrelationsByWorkout/'+workoutId);
  }

  public getMonitoringGroups(data) {
    return this.http.post(this.baseUrl+'/api/RosterApi/GetMonitoringGroups', data);
  }

   public getTrainingMaxes(athleteId, exercises, queryDate = null, useMostRecent = false): Observable<any[]> {
     var data = {
       AthleteID: athleteId,
       Exercises: exercises,
       QueryDate: queryDate,
       UseMostRecent: useMostRecent
     }
    return this.http.post<any[]>(this.baseUrl+'/api/RosterApi/TrainingMaxScores', data);
  }


  public getClassTrainingMaxes(exerciseId, classId, date): Observable<any[]> {
    var data = {
      ExerciseID: exerciseId,
      ClassID: classId,
      Date: date
    }
   return this.http.post<any[]>(this.baseUrl+'/api/RosterApi/GetTrainingEntriesByDate', data);
 }

 public getClassTrainingMaxesBeforeDate(exerciseId, classId, date): Observable<any[]> {
  var data = {
    ExerciseID: exerciseId,
    ClassID: classId,
    Date: date
  }
 return this.http.post<any[]>(this.baseUrl+'/api/RosterApi/GetTrainingEntriesBeforeDate', data);
}

 public getTestsForPeriod(exerciseId, classId, testTemplateId): Observable<any[]> {
  var data = {
    ExerciseID: exerciseId,
    ClassID: classId,
    TestTemplateID: testTemplateId
  }
 return this.http.post<any[]>(this.baseUrl+'/api/RosterApi/ScoresForPeriod', data);
 }

 public checkHasStationLockPin(): Observable<boolean> {
   return this.http.get<boolean>(this.baseUrl+'/api/AccountApi/HasStationLockPin');
 }

 public updateStationLockPin(data) {
  return this.http.post(this.baseUrl+'/api/AccountApi/UpdateStationLockPin', data);
 }

 public tryUnlockStationAppMode(data) {
   return this.http.post(this.baseUrl+'/api/AccountApi/TryUnlockStationAppMode', data);
 }

  public getTestTemplateAthleteScores(athleteId, testTemplateId)
  {
    var data = {
       AthleteID: athleteId,
       TestTemplateID: testTemplateId
     }
    return this.http.post(this.baseUrl+'/api/RosterApi/TestTemplateAthleteScores', data);
  }

  public getExerciseIdsForWorkout(wo, burnoutOnly, correlations)
  {
    var exerciseIds = [];
    if(wo.OutlineExercises && wo.OutlineExercises.length > 0)
    {
      for(var i = 0; i < wo.OutlineExercises.length; i++)
      {
        var ex = wo.OutlineExercises[i].WorkoutExercise;
        if(exerciseIds.indexOf(ex.ExerciseId < 0) && (!burnoutOnly || ex.BurnoutSet))
        {
          exerciseIds.push(ex.ExerciseId);
        }
      }
    }
    if(wo.RotationalTimers)
    {
      for(var i = 0; i < wo.RotationalTimers.length; i++)
      {
        var timer = wo.RotationalTimers[i];
        for(var k = 0; k < timer.Exercises.length; k++)
        {
          var ex = timer.Exercises[k].WorkoutExercise;
          if(exerciseIds.indexOf(ex.ExerciseId < 0) && (!burnoutOnly || ex.BurnoutSet))
          {
            exerciseIds.push(ex.ExerciseId);
          }
        }
      }
    }
    if(wo.ListTimers)
    {
      for(var i = 0; i < wo.ListTimers.length; i++)
      {
        var timer = wo.ListTimers[i];
        for(var k = 0; k < timer.Exercises.length; k++)
        {
          var ex = timer.Exercises[k].WorkoutExercise;
          if(exerciseIds.indexOf(ex.ExerciseId < 0) && (!burnoutOnly || ex.BurnoutSet))
          {
            exerciseIds.push(ex.ExerciseId);
          }
        }
      }
    }
    if(wo.StationTimers)
    {
      for(var i = 0; i < wo.StationTimers.length; i++)
      {
        var timer = wo.StationTimers[i];
        for(var k = 0; k < timer.Stations.length; k++)
        {
          var station = timer.Stations[k];
          for(var g = 0; g < station.StationExercises.length; g++)
          {
            var ex = station.StationExercises[g].WorkoutExercise;
            if(exerciseIds.indexOf(ex.ExerciseId < 0) && (!burnoutOnly || ex.BurnoutSet))
            {
              exerciseIds.push(ex.ExerciseId);
            }
          }
        }
      }
    }

    if(correlations) {
      const filteredCorrelations = correlations.filter(x => exerciseIds.indexOf(x.CorrelateTo) >= 0);
      const correlateFromIds = filteredCorrelations.map(x => x.CorrelateFrom);
      exerciseIds = exerciseIds.concat(correlateFromIds);
    }

    return exerciseIds;
  }

  public getExercisesForWorkout(wo, burnoutOnly)
  {
    var exerciseIds = [];
     if(wo.OutlineExercises && wo.OutlineExercises.length > 0)
    {
      for(var i = 0; i < wo.OutlineExercises.length; i++)
      {
        var ex = wo.OutlineExercises[i].WorkoutExercise;
        if(exerciseIds.indexOf(ex.ExerciseId < 0) && (!burnoutOnly || ex.BurnoutSet))
        {
          exerciseIds.push({id: ex.ExerciseId, name: ex.Exercise.ExerciseName});
        }
      }
    }
    if(wo.RotationalTimers)
    {
      for(var i = 0; i < wo.RotationalTimers.length; i++)
      {
        var timer = wo.RotationalTimers[i];
        for(var k = 0; k < timer.Exercises.length; k++)
        {
          var ex = timer.Exercises[k].WorkoutExercise;
          if(exerciseIds.indexOf(ex.ExerciseId < 0) && (!burnoutOnly || ex.BurnoutSet))
          {
            exerciseIds.push({id: ex.ExerciseId, name: ex.Exercise.ExerciseName});
          }
        }
      }
    }
    if(wo.ListTimers)
    {
      for(var i = 0; i < wo.ListTimers.length; i++)
      {
        var timer = wo.ListTimers[i];
        for(var k = 0; k < timer.Exercises.length; k++)
        {
          var ex = timer.Exercises[k].WorkoutExercise;
          if(exerciseIds.indexOf(ex.ExerciseId < 0) && (!burnoutOnly || ex.BurnoutSet))
          {
            exerciseIds.push({id: ex.ExerciseId, name: ex.Exercise.ExerciseName});
          }
        }
      }
    }
    if(wo.StationTimers)
    {
      for(var i = 0; i < wo.StationTimers.length; i++)
      {
        var timer = wo.StationTimers[i];
        for(var k = 0; k < timer.Stations.length; k++)
        {
          var station = timer.Stations[k];
          for(var g = 0; g < station.StationExercises.length; g++)
          {
            var ex = station.StationExercises[g].WorkoutExercise;
            if(exerciseIds.indexOf(ex.ExerciseId < 0) && (!burnoutOnly || ex.BurnoutSet))
            {
              exerciseIds.push({id: ex.ExerciseId, name: ex.Exercise.ExerciseName});
            }
          }
        }
      }
    }
    return exerciseIds;
  }

  public linkRemoteLoginCode(code) {
    return this.http.post(this.baseUrl+'/api/AccountApi/LinkRemoteLoginCodeToAccount', {LoginCode: code});
  }

  public getFullExercises(): Observable<any> {
    return this.http.get(this.baseUrl+'/api/ExerciseApi/Get');
  }

  public getCoachFullExercises(coachId): Observable<any> {
    return this.http.get(this.baseUrl+'/api/ExerciseApi/GetCoachExercises/'+coachId);
  }

  public getCurrentAthleteClasses() {
    return this.http.get(this.baseUrl+'/api/RosterApi/GetCurrentAthleteClasses', {withCredentials: true});
  }
  
  private getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  public getDataSettings() {
    return this.http.get(this.baseUrl+'/api/RosterApi/GetDataSettings');
  }

  public createRoster(name) {
    return this.http.post(this.baseUrl+'/api/RosterApi/CreateRoster', { Name: name });
  }

  private loadUserId(): Observable<number> {
   return this.http.get<number>(this.baseUrl+'/Account/GetCurrentUserID');
  }

  public getUserId() {
    return new Observable(observer => {
        if(this.userId)
        {
          observer.next(this.userId);
          observer.complete();
        }
        else {
          this.loadUserId().subscribe(res => {
            this.userId = res;
            observer.next(res);
            observer.complete();
          });
        }
      });
  }

  public getScoresForProfileChart(exerciseId, athleteId) {
    return this.http.get(this.baseUrl+`/api/RosterApi/GetScoresForProfileChart?exerciseId=${exerciseId}&athleteId=${athleteId}`);
  }

  public getTrainingDataForProfileChart(exerciseId, athleteId) {
    return this.http.get(this.baseUrl+`/api/RosterApi/GetTrainingDataForProfileChart?exerciseId=${exerciseId}&athleteId=${athleteId}`);
  }

  public getAllClasses(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl+'/api/RosterApi/Get');
  }

  public removeAthleteFromClass(classId, athleteId): Observable<any> {
    return this.http.delete(this.baseUrl+`/api/RosterApi/RemoveAthleteClass?classId=${classId}&athleteId=${athleteId}`);
  }

  public searchAthletes(terms) {
    return this.http.get<any[]>(`${this.baseUrl}/api/AthleteApi/Search?query=${terms}`);
  }

  public addAthletesToClass(data) {
    return this.http.post(`${this.baseUrl}/api/AthleteApi/AddAthletesToClass`, data);
  }

}
