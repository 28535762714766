import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private controllerPath: string = environment.baseUrl + '/api/Message/';

  constructor(private http: HttpClient) { }

  getConversations(isAthlete): Observable<any[]> {
    return this.http.get<any[]>(this.controllerPath + 'GetConversations?isAthlete=' + isAthlete);
  }

  getConversation(id: number, skip: number, take: number): Observable<any> {
    return this.http.get(this.controllerPath + `GetConversation?id=${id}&skip=${skip}&take=${take}`);
  }

  sendMessage(data): Observable<number> {
    return this.http.post<number>(this.controllerPath + 'SendMessage', data);
  }

  setMessageAsRead(message): Observable<any> {
    return this.http.post(this.controllerPath + 'SetMessageAsRead', message);
  }

  getNumberOfUnreadMessages(): Observable<any> {
    return this.http.get(this.controllerPath + 'GetNumberOfUnreadMessages');
  }

  getTeamMessengerRecipientData(isAthlete): Observable<any> {
    return this.http.get(this.controllerPath + 'GetTeamMessengerRecipientData?isAthlete=' + isAthlete);
  }
  
  getDistinctDepthChartPositions(): Observable<any[]> {
    return this.http.get<any[]>(`${this.controllerPath}GetDistinctDepthChartPositions`);
  }

  getAthletesForDepthChartPosition(positionName): Observable<any[]> {
    return this.http.get<any[]>(`${this.controllerPath}GetAthletesForDepthChartPosition?positionName=${positionName}`);
  }

  deleteThreadMessage(id) {
    return this.http.delete(`${this.controllerPath}DeleteThreadMessage?id=${id}`);
  }

  setFirebaseUserToken(token) {
    return this.http.get(`${this.controllerPath}SetFirebaseUserToken?token=${token}`);
  }

  removeFirebaseUserToken(token) {
    return this.http.get(`${this.controllerPath}RemoveFirebaseUserToken?token=${token}`);
  }

  getConversationName(conversation, thisUserId) {
    if (conversation.ThreadTitle.length > 0) {
      return conversation.ThreadTitle;
    }

    let usersString = '';
    for (let i = 0; i < conversation.ThreadUserProfiles.length; i++) {
      const user = conversation.ThreadUserProfiles[i];
      let userId = user.UserId || user.userId || user.AthleteUserId;
      if (userId.toString() !== thisUserId || conversation.ThreadUserProfiles.length === 1)
        if(user.FullName || user.fullName) {
          usersString += (user.FullName || user.fullName) + ', ';
        }
        else {
          usersString += user.FirstName + ' ' + user.LastName + ', ';
        }
    }

    return usersString.substr(0, usersString.length - 2);
  }

  getMessageWorkout(workout): Observable<any> {
    return this.http.post(`${this.controllerPath}GetMessageWorkout`, workout);
  }

  downloadWorkout(messageWorkoutId) {
    return this.http.get(`${this.controllerPath}DownloadWorkout?messageWorkoutId=${messageWorkoutId}`);
  }

}
