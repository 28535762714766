<div #pageContainer (window:resize)=onResize($event) class="embed-wrapper-container">
    <a *ngIf="safeUrl && !isHudlVideo" class="embedly-card"
            [href]="safeUrl"
            data-card-via="rackperformance.com" 
            data-card-width="100%" 
            data-card-key="7e8b383c3f1c4a249222bcd5e62e52dc"
            data-card-controls="0"></a> 

    <iframe
        *ngIf="safeUrl && isHudlVideo"
        [src]='safeUrl'
        width="100%"
        [height]="playerHeight"
        frameborder='0'
        allowfullscreen></iframe>
</div>
