import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { NbChatComponent } from '@nebular/theme';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import { NbChatMessageFile } from '../../../../../node_modules/@nebular/theme/components/chat/chat-message-file.component';
import { ToastService } from '../../../services/toast.service';
import { MessageService } from '../../../services/message.service';
import { AttachedContentType } from '../../../@core/data/social-types';

declare type NbNullableInput = string | null | undefined;
declare type NbBooleanInput = boolean | NbNullableInput;
function convertToBoolProperty(val: any): boolean {
  if (typeof val === 'string') {
    val = val.toLowerCase().trim();

    return (val === 'true' || val === '');
  }

  return !!val;
}

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
    animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(-100%)' }),
        animate(80),
      ]),
      transition('* => void', [
        animate(80, style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ChatMessageComponent {
  AttachedContentType = AttachedContentType;

  @HostBinding('@flyInOut')
  get flyInOut() {
    return true;
  }

  @HostBinding('class.not-reply')
  get notReply() {
    return !this.reply;
  }

  avatarStyle: SafeStyle;
  hoverIndex: number = -1;

  /**
   * Determines if a message is a reply
   */
  @Input()
  @HostBinding('class.reply')
  get reply(): boolean {
    return this._reply;
  }
  set reply(value: boolean) {
    this._reply = convertToBoolProperty(value);
  }
  protected _reply: boolean = false;
  static ngAcceptInputType_reply: NbBooleanInput;

  /**
   * Message sender
   * @type {string}
   */
  @Input() message: any;;
  @Input() messageText: string;
  @Input() messageId: any;

  /**
   * Message sender
   * @type {string}
   */
  @Input() sender: string;

  /**
   * Message send date
   * @type {Date}
   */
  @Input() date: Date;

  /**
   * Message send date format, default 'shortTime'
   * @type {string}
   */
  @Input() dateFormat: string;

  /**
   * Array of files `{ url: 'file url', icon: 'file icon class' }`
   */
  @Input() files: NbChatMessageFile[];

  /**
   * Quoted message text
   * @type {string}
   */
  @Input() quote: string;

  /**
   * Map latitude
   * @type {number}
   */
  @Input() latitude: number;

  /**
   * Map longitude
   * @type {number}
   */
  @Input() longitude: number;

  /**
   * Message Index
   * @type {number}
   */
  @Input() index: number;

  /**
   * Message send avatar
   * @type {string}
   */
  @Input()
  set avatar(value: string) {
    this.avatarStyle = value ? this.domSanitizer.bypassSecurityTrustStyle(`url(${value})`) : null;
  }

  /**
   * Message type, available options `text|file|map|quote`
   * @type {string}
   */
  @Input() type: string;

  @Input() workouts: any[];

  @Output() workoutSelected: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  attachedContent: any[] = [];

  constructor(protected domSanitizer: DomSanitizer,
              private messageService: MessageService,
              private notificationService: ToastService) {
  }

  ngOnInit() {
    if(this.workouts) {
      for(let workout of this.workouts) {
        workout.Type = AttachedContentType.Workout;
        workout.Name = workout.WorkoutName;
        this.attachedContent.push(workout);
      }
    }
  }

  getInitials(): string {
    if (this.sender) {
      const names = this.sender.split(' ');

      return names.map(n => n.charAt(0)).splice(0, 2).join('').toUpperCase();
    }

    return '';
  }

  onHover(index: number) {
    this.hoverIndex = index;
  }

  deleteMessage() {
    this.onDelete.emit(this.message);
  }

  // showWorkoutSelection() {
  //   for(let i = 0; i < this.workouts.length; i++) {
  //     let workout = this.workouts[i];
  //     workout.MessageId = this.messageId;
  //   }
  //   this.workoutSelected.emit(this.workouts);
  // }

  previewContent(content) {
    if(content.Type === AttachedContentType.Workout) {
      this.workoutSelected.emit([content]);
    } else if(content.Type === AttachedContentType.PracticePlan) {
      // this.dialogService.open(PreviewPracticePlanDialogComponent, {
      //   context: { practicePlan: content }
      // });
    }
  }

  downloadPostContent(event, content) {
    // this.loading = true;
    if(event !== null) {
      event.stopPropagation();
      event.preventDefault();
    }
    if(content.Type === AttachedContentType.Workout) {
      // let workout = this.attachedContent.find(x => x.MessageProgramId === content.MessageProgramId);
      // workout.Loading = true;
      this.messageService.downloadWorkout(content.MessageProgramId).subscribe(result => {
        // this.loading = false;
        this.notificationService.success('Workout downloaded into Default Folder');
      }, e => {
        // this.loading = false;
        this.notificationService.error('There was a problem downloading the workout');
        console.error(e);
      });
    } else if(content.Type === AttachedContentType.PracticePlan) {

    }
  }

  removeContentFromPost(content) {
    // this.post.AttachedContent.splice(this.post.AttachedContent.indexOf(content), 1);
    // if(content.Type === AttachedContentType.Workout) {
    //   this.onSavePostCalls.push(this.socialService.deletePostWorkout(content.Id, this.post.Id));
    // } else if(content.Type === AttachedContentType.PracticePlan) {

    // }
  }

}
