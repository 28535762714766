import { Component, Input, OnInit, Output, EventEmitter, NgZone } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { AttachedContentType } from '../../../@core/data/social-types';
import { MessageService } from '../../../services/message.service';
import { ToastService } from '../../../services/toast.service';
import { forkJoin } from 'rxjs';
import { PreviewWorkoutDialogComponent } from '../preview-workout-dialog/preview-workout-dialog.component';
import { WorkoutOutlineDialogComponent } from '../workout-outline-dialog/workout-outline-dialog.component';
import { ChooseOptionDialogComponent, OptionsType } from '../choose-option-dialog/choose-option-dialog.component';

@Component({
  selector: 'ngx-attached-content',
  templateUrl: './attached-content.component.html',
  styleUrls: ['./attached-content.component.scss']
})
export class AttachedContentComponent implements OnInit {
  @Input() attachments = [];
  @Input() groupAttachments = false;
  @Input() canEdit = false;
  @Input() canDownload = false;
  @Input() reversedStyling = false;
  AttachedContentType = AttachedContentType;
  OptionsType = OptionsType;

  constructor(private zone: NgZone,
              private dialogService: NbDialogService,
              private messageService: MessageService,
              private toastService: ToastService) { }

  ngOnInit(): void {
  }

  removeContentItem(attachment) {
    const index = this.attachments.indexOf(attachment);
    if (index !== -1) {
      this.attachments.splice(index, 1);
    }
  }

  download(attachments) {
    const calls = [];

    const downloadMessageAttachment = (attachment) => {
      if(event !== null) {
        event.stopPropagation();
        event.preventDefault();
      }
      if(attachment.Type === AttachedContentType.Workout) {
        calls.push(this.messageService.downloadWorkout(attachment.MessageProgramId));
      } else if(attachment.Type === AttachedContentType.PracticePlan) {
        //add call for practice plan downloads here
      }
    }

    const downloadAttachment = (attachment) => {
    }

    for (let attachment of attachments) {
      if (attachment.MessageProgramId) {
        downloadMessageAttachment(attachment);
      } else {
        downloadAttachment(attachment);
      }
    }

    forkJoin(calls).subscribe(() => {
      this.toastService.success('Successfully downloaded');
    }, err => {
      this.toastService.error('There was a problem downloading');
      console.error(err);
    });
  }

  preview(attachment, event) {
    event.stopPropagation();
    const previewMessageWorkout = () => {
      this.zone.run(() => {
        this.dialogService.open(WorkoutOutlineDialogComponent, {
          context: {
            workouts: [attachment]
          },
          hasBackdrop: true
        });
      });
    }

    const previewWorkout = () => {
      this.dialogService.open(PreviewWorkoutDialogComponent, {
        context: {
          workout: [attachment]
        },
        hasBackdrop: true
      });
    }

    const previewPracticePlan = () => {
      // this.dialogService.open(PreviewPracticePlanDialogComponent, {
        //   context: { practicePlan: content }
        // });
    }

    if (attachment.Type === AttachedContentType.Workout) {
      if (attachment.MessageProgramId) {
        previewMessageWorkout();
      } else {
        previewWorkout();
      }
    } else if (attachment.Type === AttachedContentType.PracticePlan) {
      previewPracticePlan();
    }
  }

  showSelectAttachmentDialog(event) {
    event.stopPropagation();
    this.dialogService.open(ChooseOptionDialogComponent, {
      context: {
        title: 'Select Attachment',
        description: 'Choose one of the following attachments that you would like to view.',
        options: this.attachments,
        optionsType: this.OptionsType.AttachedContent
      }
    }).onClose.subscribe(result => {
      if (result) {
        this.preview(result, null);
      }
    });
  }

}

