<div>
    <div *ngFor="let notification of notifications" class="notification">
        <ng-containger *ngIf="notification.type != 10">
            <span [innerHtml]="notification.text"></span>
        </ng-containger>
        <ng-container *ngIf="notification.type == 10">
            <div class="invite">
                <div style="width: 280px" [innerHtml]="notification.text"></div>
                <div>
                    <button style="margin-right: 5px" nbButton status="primary" (click)="respondToGroupInvite(true, notification)">
                        <nb-icon icon="checkmark-outline"></nb-icon>
                    </button>
                    <button nbButton status="danger" (click)="respondToGroupInvite(false, notification)">
                        <nb-icon icon="close-outline"></nb-icon>
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
