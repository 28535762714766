import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ExerciseOptionsService {

  public exerciseOptionsTable: any = null;
  private optionsSetForAthlete: Boolean = false;
  constructor(public dataService: DataService) {
  }

  public loadExercisesForCoach() {
    return this.dataService.getFullExercises().subscribe((data) => {
      this.exerciseOptionsTable = {};
      for (let i = 0; i < data.length; i++) {
        this.exerciseOptionsTable[data[i].Id] = data[i];
      }
    });
  }

  public getExerciseOptionById(id) {
    return this.exerciseOptionsTable[id];
}

  public getExerciseOptionsById(id, coachId): Observable<any> {
    return new Observable(observer => {
      if (this.exerciseOptionsTable) {
        observer.next(this.exerciseOptionsTable[id]);
        observer.complete();
      } else if (!coachId) {
        this.dataService.getFullExercises().subscribe((data) => {
          this.exerciseOptionsTable = {};
          for (let i = 0; i < data.length; i++) {
            this.exerciseOptionsTable[data[i].Id] = data[i];
          }
          observer.next(this.exerciseOptionsTable[id]);
          observer.complete();
        });
      } else {
        this.setOptionsForAthlete(coachId).subscribe(() => {
          observer.next(this.exerciseOptionsTable[id]);
          observer.complete();
        });
      }

    });
  }

  public setOptionsForAthlete(coachId)
  {
    return new Observable(observer => {
      if(!this.optionsSetForAthlete)
      {
        this.exerciseOptionsTable = {};
        this.dataService.getCoachFullExercises(coachId).subscribe((data) => {
          for(var i = 0; i < data.length; i++) {
            this.exerciseOptionsTable[data[i].Id] = data[i];
          }
          this.optionsSetForAthlete = true;
          observer.next();
          observer.complete();
        })
      } else {
        observer.next();
        observer.complete();
      }
    });
  }
}
