import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantService {
  readonly ONE_HUNDRED = 100;

  constructor() { }
}
