import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'embed-wrapper',
  templateUrl: './embed-wrapper.component.html',
  styleUrls: ['./embed-wrapper.component.scss']
})
export class EmbedWrapperComponent implements OnInit {
  VIDEO_ASPECT_RATIO: number = 9.0 / 16;
  HUDL_VIDEO_MATCH: string = 'hudl.com/video';
  YOUTUBE_VIDEO_MATCH = 'youtube.com/';

  @Input() url: string;
  safeUrl: SafeResourceUrl;
  playerHeight: number = 0;
  isHudlVideo: boolean = false;

  @ViewChild('pageContainer') pageContainer: ElementRef;

  constructor(private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    if(!this.url) {
      return;
    }

    if(this.url.includes(this.HUDL_VIDEO_MATCH)) {
      this.isHudlVideo = true;
    } else if (this.url.includes(this.YOUTUBE_VIDEO_MATCH)) {
      if(this.url.includes('/shorts/')) {
        this.url = this.url.replace('/shorts/', '/embed/');
      }
    }

    let stringUrl = this.url;
    if(this.isHudlVideo) {
      const url = new URL(this.url);
      stringUrl = `https://www.hudl.com/embed${url.pathname}`;
    }

    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(stringUrl);
  }

  ngAfterViewInit(): void {
    setTimeout(() => { this.setPlayerHeight() }, 100);
  }

  setPlayerHeight(): void {
    const elem = this.pageContainer?.nativeElement;
    if(!elem) {
      return;
    }

    this.playerHeight = elem.clientWidth * this.VIDEO_ASPECT_RATIO;
    this.cdr.detectChanges();
  }

  onResize(event: Event): void {
    this.setPlayerHeight();
  }
}
