import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { ToastService } from '../../../../services/toast.service';

@Component({
  selector: 'ngx-social-post-options-dialog',
  templateUrl: './social-post-options-dialog.component.html',
  styleUrls: ['./social-post-options-dialog.component.scss']
})
export class SocialPostOptionsDialogComponent implements OnInit {
  menuItems;
  editing;
  showEdit: boolean = false;
  showDelete: boolean = false;

  constructor(protected dialogRef: NbDialogRef<SocialPostOptionsDialogComponent>, 
    private dialogService: NbDialogService, private toastService: ToastService) { }

  ngOnInit(): void {
    for(let item of this.menuItems) {
      if(item.title === 'Edit' && !this.editing) this.showEdit = true;
      if(item.title === 'Delete') this.showDelete = true;
    }
  }

  close() {
    this.dialogRef.close();
  }

  editPost() {
    this.dialogRef.close("edit");
  }

  deletePost() {
    this.dialogRef.close("delete");
  }
}
