<div class="file-drop-zone" [class.image-selected]="previewUrl">
    <input type="file" 
        [accept]='isImage ? "image/*" : ""' 
        [multiple]="multiple" 
        #thumbnail 
        (change)="onChange($event)">
    <div class="field-title">
        Drag and drop {{ isImage ? 'image' : 'file'}}{{ multiple ? 's' : '' }} here
    </div>
    <button nbButton status="primary">
        Browse for file{{ multiple ? 's' : '' }}
    </button>
    <img class="image-preview" [src]="previewUrl" [style.border-radius]="cropOptions.roundCropper ? '50%' : ''"/>
</div>
