import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MessageService } from './message.service';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CordovaService {
  nativeAudio: NativeAudioPlugin.NativeAudio;
  fcmTokenStorageString: string = 'fcm-token';
  queuedMessageThread: number;
  queuedSchoolId: any;
  queuedTeamId: any;
  queuedDepthChartId: any;
  public onPause: EventEmitter<any> = new EventEmitter();
  public onResume: EventEmitter<any> = new EventEmitter();
  public onMessageTap: EventEmitter<any> = new EventEmitter();
  public onDepthChartTap: EventEmitter<any> = new EventEmitter();
  public offline: EventEmitter<any> = new EventEmitter();
  public online: EventEmitter<any> = new EventEmitter();
  watchdogThread: any;
  window: any = window;
  loadedSoundKeys = [];


  constructor(private messageService: MessageService, private router: Router) {
    if (window.plugins && window.plugins.NativeAudio) {
      this.nativeAudio = window.plugins.NativeAudio;
    }
  }

  openLinkInSystemBrowser(url: string) {
    if (this.window.cordova?.InAppBrowser) {
      this.window.cordova.InAppBrowser.open(url, '_system');
    } else {
      window.open(
        url, "_blank");
    }

  }

  keepAwake() {
    if (window.plugins && window.plugins.insomnia) {
      window.plugins.insomnia.keepAwake();
    }

    if(this.getPlatform() == 'ios' && this.nativeAudio) {
      // Need to load a sound from native audio to keep ios alive in background
      this.nativeAudio.preloadSimple('work', '', () => {}, e => {
        console.error(e);
      });
    }

    const theWindow: any = window;
    const backgroundMode = theWindow?.cordova?.plugins?.backgroundMode;
    if(backgroundMode) {
      backgroundMode.disableBatteryOptimizations();
      backgroundMode.on('activate', function() {
        backgroundMode.disableWebViewOptimizations();
      });
      backgroundMode.enable();
    }
  }
  
  allowSleepAgain() {
    if (window.plugins && window.plugins.insomnia) {
      window.plugins.insomnia.allowSleepAgain();
    }
    const theWindow: any = window;
    const backgroundMode = theWindow?.cordova?.plugins?.backgroundMode;
    if(backgroundMode) {
      theWindow.cordova.plugins.backgroundMode.disable();
      if(this.watchdogThread) {
        this.watchdogThread.unsubscribe();
        this.watchdogThread = null;
      }
    }
  }


  sendNotifications(notifications) {
    const theWindow: any = window;
    const localNotifications = theWindow?.cordova?.plugins?.notification?.local;
    if(localNotifications) {
      const isAndroid = this.getPlatform() === 'android';
      for(let i = 0; i < notifications.length; i++) {
        let notification = notifications[i];
        if(isAndroid) {
          notification.smallIcon = 'res://icon/notification/drawable-mdpi/notification_icon.png';
        }
        notification.foreground = false;
      }

      localNotifications.schedule(notifications);
    }
  }

  clearNotifications() {
    const theWindow: any = window;
    const localNotifications = theWindow?.cordova?.plugins?.notification?.local;
    if(localNotifications) {
      localNotifications.cancelAll();
      localNotifications.clear();
    }
  }

  loadSound(key, url) {
    if (this.nativeAudio && this.loadedSoundKeys.indexOf(key) < 0) {
      this.nativeAudio.preloadSimple(key, url,
        () => { this.loadedSoundKeys.push(key); },
        (err) => { console.error(err); }
      );
    }
  }

  getPlatform() {
    const theWindow: any = window;
    return theWindow?.cordova?.platformId;
  }

  playSound(id) {
    if (this.nativeAudio) {
      return this.nativeAudio.play(id, () => console.log('played sound'), err => console.error(err));
    }
  }

  private getFirebaseObj() {
    const theWindow: any = window;
    const firebase = theWindow.FirebasePlugin;
    return firebase;
  }

  getNotificationToken() {
    const firebase = this.getFirebaseObj();
    if(!firebase) return;
  }

  getFirebaseToken(): Observable<string> {
    const firebase = this.getFirebaseObj();
    if(!firebase) return;

    const storedFcmToken = localStorage.getItem(this.fcmTokenStorageString);
    return new Observable<string>(observer => {
      if(storedFcmToken && storedFcmToken.length > 0) {
        observer.next(storedFcmToken);
        observer.complete();
        return;
      }

      firebase.onTokenRefresh((fcmToken) => {
        localStorage.setItem(this.fcmTokenStorageString, fcmToken);
        observer.next(fcmToken);
        observer.complete();
      }, (e) => {
        observer.error(e);
        observer.complete();
      });
    });
  }

  registerFirebase() {
    const firebase = this.getFirebaseObj();
    if(!firebase) return;
    firebase.onTokenRefresh((fcmToken) => {
      localStorage.setItem(this.fcmTokenStorageString, fcmToken);
      this.messageService.setFirebaseUserToken(fcmToken).subscribe(() => {});
    }, (error) => {
      console.error(error);
    });
    firebase.grantPermission();
  }

  unregisterFirebase() {
    const firebase = this.getFirebaseObj();
    if(!firebase) return;

    const fcmToken = localStorage.getItem(this.fcmTokenStorageString);

    if(fcmToken && fcmToken.length > 0) {
      this.messageService.removeFirebaseUserToken(fcmToken).subscribe(() => {});
    }

    firebase.unregister();
  }

  setFirebaseEvents() {
    const firebase = this.getFirebaseObj();
    if(!firebase) return;
    firebase.grantPermission(() => {}, () => {});

    firebase.onMessageReceived((message) => {
      firebase.grantPermission(() => {}, () => {});
      
      if(message.tap) {
        if(message.threadId?.length > 0) {
          this.queuedMessageThread = parseInt(message.threadId);
        } else if(message.schoolId?.length > 0) {
          this.queuedSchoolId = message.schoolId;
        } else if(message.teamId?.length > 0 && message.depthChartId?.length > 0)  {
          this.queuedDepthChartId = message.depthChartId;
          this.queuedTeamId = message.teamId;
        } else if(message.teamId?.length > 0) {
          this.queuedTeamId = message.teamId;
        }
        this.onMessageTap.emit();
      }
    });
  }

}
