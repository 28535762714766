import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-compose-notes-dialog',
  templateUrl: './compose-notes-dialog.component.html',
  styleUrls: ['./compose-notes-dialog.component.scss']
})
export class ComposeNotesDialogComponent implements OnInit {
  readonly: boolean = true;
  title: string;
  notes: any;

  constructor(private dialogRef: NbDialogRef<ComposeNotesDialogComponent>,
              private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    if(this.readonly && typeof this.notes === 'string') { 
      this.notes = this.sanitizer.bypassSecurityTrustHtml(this.notes); 
    }
  }

  back() {
    this.dialogRef.close();
  }

}
