import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Workout } from '../@core/data/workout';
import { ExerciseHistory } from '../pages/workout/exercise-history/exercise-history.component';

@Injectable({
  providedIn: 'root'
})
export class WorkoutService {
  private folders: any;
  private sharedFolders: any;
  private basePath = '/api/WorkoutApi';
  private baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getWorkoutFolders(): Observable<any> {
    return this.http.get(this.baseUrl+'/api/WorkoutApi/GetWorkoutFolders');
  }

  getDataSettings() {
    return this.http.get(this.baseUrl+'/api/RosterApi/GetDataSettings');
  }

  recordTrainingMax(athleteId, reps, weight, workoutId, exerciseId, repsOnly = false): any {
    const data = {
      AthleteID: athleteId,
      Reps: reps,
      Weight: weight,
      WorkoutID: workoutId,
      ExerciseID: exerciseId,
      RepsOnly: repsOnly
    }
    return this.http.post(this.baseUrl+'/api/RosterApi/RecordTrainingMax', data);
  }

  recordSetTrainingEntry(data) {
    return this.http.post(this.baseUrl+'/api/RosterApi/RecordSetTrainingEntry', data);
  }

  recordTrainingMaxWithDate(athleteId, reps, weight, workoutId, exerciseId, date, repsOnly = false): any {
    const data = {
      AthleteID: athleteId,
      Reps: reps,
      Weight: weight,
      WorkoutID: workoutId,
      ExerciseID: exerciseId,
      DateEntered: date,
      RepsOnly: repsOnly
    }
    return this.http.post(this.baseUrl+'/api/RosterApi/RecordTrainingMax', data);
  }

  getWorkout(id: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/WorkoutApi/GetWorkout?id=${id}&loadBurnoutData=true`);
  }

  getWorkoutDto(id, loadBurnoutData = true, getFlattenedTimerList = false): Observable<Workout> {
    return this.http.get<Workout>(`${this.basePath}/GetWorkout?id=${id}&loadBurnoutData=${loadBurnoutData}&getFlattenedTimerList=${getFlattenedTimerList}`);
  }

  getTimers(id): Observable<Workout> {
    return this.http.get<Workout>(`${this.baseUrl}/api/WorkoutApi/GetWorkout?id=${id}&loadBurnoutData=true&getFlattenedTimerList=true`);
  }

  getCorrelationsForWorkout(workoutId) {
    return this.http.get(this.baseUrl+'/api/RosterApi/CorrelationsByWorkout/'+workoutId);
  }

  getWorkoutState(id): Observable<any> {
    return this.http.get(environment.baseUrl+`/api/LiveWorkoutApi/GetWorkoutState?id=${id}`);
  }

  getWorkoutOutline(id): Observable<any> {
    return this.http.get(this.baseUrl+'/api/WorkoutApi/FullWorkout/' + id);
  }

  logWorkoutComplete(data): Observable<any> {
    return this.http.post(this.baseUrl+'/api/WorkoutApi/ReportAthleteWorkoutComplete', data);
  }

  getWorkoutJournalItem(pageSize: number, index: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/WorkoutApi/GetWorkoutJournalItems?pageSize=${pageSize}&index=${index}`);
  }

  getMySounds(): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/WorkoutApi/GetTimerSounds`);
  }

  logActiveSessionSignalrIssue(logData) {
    return this.http.post(this.basePath + '/LogSignalrActiveSessionsIssue', logData);
  }

  getExerciseHistory(
    athleteId: number,
    exerciseId: number,
    take: number,
    skipTrainingMax: number,
    skipMultiSet: number): Observable<ExerciseHistory> {
      
      var timezoneOffsetMinutes = new Date().getTimezoneOffset() * -1;
      return this.http.get<ExerciseHistory>(`${this.baseUrl}/api/TrainingDataApi/GetExerciseHistory?athleteId=${athleteId}&exerciseId=${exerciseId}&timezoneOffsetMinutes=${timezoneOffsetMinutes}&take=${take}&skipTrainingMax=${skipTrainingMax}&skipMultiSet=${skipMultiSet}`);
  }

  getFolders(): Observable<any> {
    return Observable.create((observer) => {
      if (this.folders != null) {
        observer.next(this.folders);
        observer.complete();
      }
      else {
        return this.http.get(`${this.baseUrl + this.basePath}/GetWorkoutFolders`).subscribe((folders) => {
          this.folders = folders;
          observer.next(folders);
          observer.complete();
        });
      }
    });
  }

  getSharedFolders(): Observable<any> {
    return Observable.create((observer) => {
      if (this.sharedFolders != null) {
        observer.next(this.sharedFolders);
        observer.complete();
      }
      else {
        return this.http.get(`${this.baseUrl + this.basePath}/GetSharedWorkoutFolders`).subscribe((folders) => {
          this.sharedFolders = folders;
          observer.next(folders);
          observer.complete();
        });
      }
    });
  }

}
