import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  private baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) { }

  public getTeams(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + '/api/TeamApi/GetTeams');
  }
  public getTeamById(teamId): Observable<any> {
    return this.http.get(this.baseUrl + '/api/TeamApi/GetTeamById/' + teamId);
  }

  public getRostersByTeam(teamId): Observable<any> {
    return this.http.get(this.baseUrl + '/api/TeamApi/GetRostersByTeam/' + teamId);
  }

  public getPracticesByTeam(teamId): Observable<any> {
    return this.http.get(this.baseUrl + '/api/TeamApi/GetPracticesByTeam/' + teamId);
  }

  public getDepthChartsByTeam(teamId): Observable<any> {
    return this.http.get(this.baseUrl + '/api/DepthChartApi/GetDepthChartsByTeam/' + teamId);
  }

  public getTeamUsers(teamId): Observable<any> {
    return this.http.get(this.baseUrl + '/api/TeamApi/GetTeamUsers/' + teamId);
  }

  public addTeam(data) {
    return this.http.post(this.baseUrl + '/api/TeamApi/AddTeam/', data);
  }

  public updateTeam(data) {
    return this.http.put(this.baseUrl + '/api/TeamApi/UpdateTeam/', data);
  }

}
