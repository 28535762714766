<div class="page-header">
    <nb-icon class="back-button" (click)="ref.close()" icon="chevron-left-outline"></nb-icon>
    <div>{{title}}</div>
</div>

<div class="page-content">
    <nb-card>
        <nb-card-header>
            <div class="description">
                {{description}}
            </div>
        </nb-card-header>
    
        <nb-card-body *ngIf="optionsType === OptionsType.Standard">
            <nb-radio-group [(ngModel)]="selectedOption">
                <nb-radio *ngFor="let option of options" [value]="option">{{option.Name}}</nb-radio>
              </nb-radio-group>
        </nb-card-body>
        
        <nb-card-body *ngIf="optionsType === OptionsType.AttachedContent">
            <div class="attached-content-wrapper">
                <ngx-attached-content [attachments]="options" 
                                        [canDownload]="true">
                </ngx-attached-content>
            </div>
        </nb-card-body>
    </nb-card>
</div>