<div class="page-header">
    <nb-icon class="back-button" (click)="cancel()" icon="chevron-left-outline"></nb-icon>
    <div>Crop Image</div>
</div>

<div class="page-content">
    <nb-card>
        <nb-card-body>
            <!-- Zoom Controls -->
            <div class="zoom-control">
                <nb-icon icon="plus-outline" 
                        (click)="zoomIn()">
                </nb-icon>
                <nb-icon icon="minus-outline" 
                        (click)="zoomOut()">
                </nb-icon>
                <nb-icon icon="refresh-outline" (click)="zoomReset()"></nb-icon>
                <div class="percentage">{{ scale * constantService.ONE_HUNDRED }}%</div>
            </div>
            <image-cropper style="max-width: 80vw; max-height: 60vh"
                [imageFile]="image"
                [maintainAspectRatio]="cropOptions.maintainAspectRatio || false"
                [aspectRatio]="cropOptions.aspectRatio || 1"
                [roundCropper]="cropOptions.roundCropper || false"
                [transform]="transform"
                format="png"
                (imageCropped)="onCrop($event)"
            ></image-cropper>
        </nb-card-body>
        <nb-card-footer style="text-align: right">
            <button nbButton style="margin-right: 10px" status="primary" (click)="crop()">Crop</button>
            <button nbButton status="basic" (click)="cancel()">Cancel</button>
        </nb-card-footer>
    </nb-card>
</div>