import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { forkJoin } from 'rxjs';
import { CategoryTagDTO } from '../../../@core/data/group';
import { AttachedContentType, Post } from '../../../@core/data/social-types';
import { AuthService } from '../../../services/auth.service';
import { CordovaService } from '../../../services/cordova.service';
import { DataService } from '../../../services/data.service';
import { FeedCacheService } from '../../../services/feed-cache.service';
import { SocialService } from '../../../services/social.service';
import { TeamService } from '../../../services/team.service';
import { ToastService } from '../../../services/toast.service';
import { FileCropperDialogComponent } from '../file-cropper-dialog/file-cropper-dialog.component';
import { SelectWorkoutDialogComponent } from '../select-workout-dialog/select-workout-dialog.component';
import { SocialImageLightboxComponent } from '../social-image-lightbox/social-image-lightbox.component';

@Component({  
  selector: 'ngx-social-feed',
  templateUrl: './social-feed.component.html',
  styleUrls: ['./social-feed.component.scss']
})
export class SocialFeedComponent implements OnInit, OnDestroy {

  userData: any;
  profileImage: any;
  athlete: any;
  posts: any[];
  newPost: Post = new Post();
  isLoadingPosts: boolean = false;
  showLoadMore: boolean = true;
  @Input() allowPosting: boolean = true;
  @Input() filter: any;
  @Input() showTagFilter: boolean = false;
  @Input() systemId: number = null;
  @Input() groupId: number = null;
  @Input() courseId: number = null;
  @Input() userCanPost = false;
  @Input() schoolId: number = null;
  @Input() teamId: number = null;
  @Input() tags: CategoryTagDTO[];
  @Input() categories: CategoryTagDTO[];
  @Input() hasGroupAccess = false;
  savedSchoolId: number = null;
  schoolName: string = 'School';
  @Input() topScrolled: EventEmitter<any>;
  @Input() isCoach: boolean = false;
  scrolledSub: any;
  refreshSub: any;
  feedType: string = 'School';
  teams: any[] = [];
  athleteSchool: any;
  messageTapSub: any;
  posting = false;
  includeInMainFeed = false;
  AttachedContentType = AttachedContentType;


  clearTags: EventEmitter<any> = new EventEmitter<any>();
  addCategory: EventEmitter<any> = new EventEmitter<any>();
  addTag: EventEmitter<any> = new EventEmitter<any>();

  postImageCropOptions: any = {
    aspectRatio: 1,
    maintainAspectRatio: true
  };

  constructor(private authService: AuthService, private dataService: DataService, private socialService: SocialService,
    private feedCache: FeedCacheService, private teamService: TeamService, private toastService: ToastService,
    private cordovaService: CordovaService, private cdr: ChangeDetectorRef, private dialogService: NbDialogService) {
      this.newPost.IncludeInMainFeed = false;
      this.newPost.AttachedContent = [];
  }

  ngOnInit(): void {
    this.feedCache.posts = undefined;
    this.userData = this.authService.getUserData();

    if((this.groupId || this.courseId) && !this.userCanPost) {
      this.allowPosting = false;
    }
    if(this.userData.image?.toLowerCase() === '/images/profileplaceholder.png') {
      this.userData.image = undefined;
    }
    this.profileImage = this.userData.image;
    this.getProfileImage();

    const storedSchoolName = window.localStorage.getItem('school-name');
    if (storedSchoolName) {
      this.schoolName = storedSchoolName;
    } else {
      const athleteSchool = window.localStorage.getItem('athlete-school');
      if (athleteSchool) {
        this.athleteSchool = JSON.parse(athleteSchool);
        this.schoolName = this.athleteSchool.title;
      }
    }
    this.savedSchoolId = this.schoolId;
    if(this.feedCache.feedType === 'Public') {
      this.feedType = this.feedCache.feedType;
    }

    this.refreshSub = this.feedCache.refreshFeed.subscribe(() => {
      this.posts = [];
      this.loadPosts();
    });

    let teamsLoaded = false;
    if(this.feedCache.posts && this.feedCache.posts.length > 0 && !this.cordovaService.queuedSchoolId && !this.cordovaService.queuedTeamId) {
      this.posts = this.feedCache.posts;
      if(this.topScrolled) {
        this.scrolledSub = this.topScrolled.subscribe(() => {
          this.loadPosts();
          this.scrolledSub.unsubscribe();
        });
      }
    } else if(this.cordovaService.queuedTeamId) {
      teamsLoaded = true;
      this.teamService.getTeams().subscribe((teams: any[]) => {
        if (this.isCoach) {
          this.teams = teams;
        } else {
          this.teams = teams.filter((team) => team.SchoolId === this.athleteSchool.id);
        }

        let teamId = parseInt(this.cordovaService.queuedTeamId);
        let selectedTeam = this.teams.find(x => x.Id === teamId);
        if(selectedTeam) {
          setTimeout(() => {
            this.feedType = selectedTeam;
            this.feedTypeChange(selectedTeam);
          }, 100);
        } else {
          this.loadPosts();
        }
        this.cordovaService.queuedTeamId = null;
      });
    } else {
      this.cordovaService.queuedSchoolId = null;
      this.loadPosts();
    }

    this.messageTapSub = this.cordovaService.onMessageTap.subscribe(() => {
      if(this.cordovaService.queuedSchoolId) {
        this.feedType = 'School';
        this.feedTypeChange(this.feedType);
        this.cordovaService.queuedSchoolId = null;
      } else if(this.cordovaService.queuedTeamId) {
        this.feedType = this.teams.find(x => x.Id === parseInt(this.cordovaService.queuedTeamId));
        this.feedTypeChange(this.feedType);
        this.cordovaService.queuedTeamId = null;
      }
    });
  }

  ngOnDestroy() {
    if(this.refreshSub) {
      this.refreshSub.unsubscribe();
    }
    if(this.messageTapSub) {
      this.messageTapSub.unsubscribe();
    }
  }

  protected toggleIncludeInMainFeed(checked: boolean): void {
    this.includeInMainFeed = checked;
  }

  loadPosts() {
    this.isLoadingPosts = true;
    if (this.filter != null) {
      if (this.filter.userId) {
        this.socialService.getUserPosts(this.filter.userId, 0).subscribe((posts) => {
          this.updatePostViewProperties(posts);
          this.posts = posts;
          this.feedCache.posts = posts;
          this.isLoadingPosts = false;
          this.cdr.detectChanges();
        });
      }
    } else if (this.schoolId) {
      this.savedSchoolId = this.schoolId;
      this.socialService.getSchoolPosts(this.schoolId, 0).subscribe(posts => {
        if (posts.length < 20) this.showLoadMore = false;

        this.updatePostViewProperties(posts);
        this.posts = posts;
        this.feedCache.posts = posts;
        this.isLoadingPosts = false;
        this.cdr.detectChanges();
      });
    } else if (this.teamId) {
      this.socialService.getTeamPosts(this.teamId, 0).subscribe((posts) => {
        if (posts.length < 20) this.showLoadMore = false;
        this.updatePostViewProperties(posts);
        this.posts = posts;
        this.feedCache.posts = posts;
        this.isLoadingPosts = false;
        this.cdr.detectChanges();
      });
    } else if (this.groupId) {
      this.socialService.getGroupPosts(this.groupId, 0).subscribe((posts) => {
        if (posts.length < 20) this.showLoadMore = false;
        this.updatePostViewProperties(posts);
        this.posts = posts;
        this.isLoadingPosts = false;
      }, e => {
        console.error(e);
        this.isLoadingPosts = false;
      }); 
    } else if (this.courseId) {
      this.socialService.getCoursePosts(this.courseId, 0).subscribe((posts) => {
        if (posts.length < 20) this.showLoadMore = false;
        this.updatePostViewProperties(posts);
        this.posts = posts;
        this.feedCache.posts = posts;
        this.isLoadingPosts = false;
      }, e => {
        console.error(e);
        this.isLoadingPosts = false;
      });
    } else {
      this.socialService.getPosts(0, this.systemId, this.groupId).subscribe((posts) => {
        if (posts.length < 20) this.showLoadMore = false;
        this.updatePostViewProperties(posts);
        this.posts = posts;
        this.feedCache.posts = posts;
        this.isLoadingPosts = false;
        this.cdr.detectChanges();
      });
    }
  }

  updatePostViewProperties(posts: Post[]) {
    posts.forEach(post => {
      post.IsLikedByCurrentUser = post.Likes.find(l => l.UserId === parseInt(this.userData.userId, 10)) != null;
      post.DateCreated = post.DateCreated + 'Z';
      post.Comments.forEach(comment => {
        comment.IsLikedByCurrentUser = comment.Likes.find(l => l.UserId === parseInt(this.userData.userId, 10)) != null;
        comment.DateCreated = comment.DateCreated + 'Z';
      });
    });
  }

  createPost(): void {
    this.posting = true;
    this.newPost.SystemId = this.systemId;
    this.newPost.GroupId = this.groupId;
    this.newPost.CourseId = this.courseId;
    this.newPost.SchoolId = this.schoolId;
    this.newPost.TeamId = this.teamId;
    this.newPost.IncludeInMainFeed = this.includeInMainFeed;
    this.newPost.PostWorkouts = [];
    this.newPost.AttachedContent.forEach(content => {
      if (content.Type === AttachedContentType.Workout) {
        this.newPost.PostWorkouts.push(content);
      }
    });

    if (this.newPost.Content.length === 0 
      && this.newPost.AttachedContent.length === 0
      && this.newPost.Images.length === 0
      && this.newPost.ImageFiles.length === 0) {
      this.toastService.error('Post content cannot be empty');
      this.posting = false;
      return;
    }

    const imageFiles = [...this.newPost.ImageFiles];
    this.newPost.ImageFiles = undefined;
    this.socialService.createPost(this.newPost).subscribe((createdPost: Post) => {
      const uploadImageCalls = [];
      for(let file of imageFiles) {
        uploadImageCalls.push(this.socialService.addPostImage(createdPost.Id, file));
      }

      const clearNewPostData = () => {
        this.loadPosts();
        this.newPost = new Post();
        this.newPost.AttachedContent = [];
        this.newPost.ImageFiles = [];
        this.newPost.Images = [];
        this.clearTags.emit();
        this.includeInMainFeed = false;
      };

      forkJoin(uploadImageCalls).subscribe((imageIds) => {
        clearNewPostData();
        this.posting = false;
      }, (err) => {
        console.error(err);
        this.newPost.ImageFiles = imageFiles;
        this.posting = false;
      });
      if(uploadImageCalls.length === 0) { 
        clearNewPostData();
        this.posting = false; 
      }
    }, e => {
      console.error(e);
      this.toastService.error('There was a problem saving the post');
      this.posting = false;
    });
  }

  postImageUploaded(event) {
    const files = event.target.files;
    let index = 0;

    const openCropper = (file) => {
      index++;
      const dialogContext: any = {
        image: file,
        cropOptions: this.postImageCropOptions
      };

      const dataURLtoFile = (dataurl, filename): File => {

        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: mime});
      };

      const dialogRef = this.dialogService.open(FileCropperDialogComponent,
      { 
          context: dialogContext, 
          closeOnBackdropClick: false, 
          dialogClass: 'model-full' 
      });

      dialogRef.onClose.subscribe((imageData) => {
        if (files[index]) { openCropper(files[index]); }
        if (!imageData) { return; }
        file = dataURLtoFile(imageData, 'newProfilePic.png');

        const mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
          this.toastService.error('Only images are supported.');
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          file.url = String(reader.result);
          const img = {
            Id: -1,
            Url: file.url
          }
          this.newPost.Images.push(img);
          this.newPost.ImageFiles.push(file);
        };

      });
    }

    openCropper(files[index]);
  }

  removePostImage(image) {
    const matchingFile = this.newPost.ImageFiles.find(file => file.url === image.Url);
    const fileIndex = this.newPost.ImageFiles.indexOf(matchingFile);
    this.newPost.ImageFiles.splice(fileIndex, 1);

    const matchingImg = this.newPost.Images.find(img => img.Url === image.Url);
    const imgIndex = this.newPost.Images.indexOf(matchingImg);
    this.newPost.Images.splice(imgIndex, 1);
  }

  openImage(selectedImage): void {
    const index = this.newPost.Images.indexOf(selectedImage);
    const album = [];

    for(let image of this.newPost.Images) {
      const img = {
        id: image.Id,
        thumbnail: image.Url,
        caption: '',
        src: image.Url
      };
      album.push(img);
    }
    this.dialogService.open(SocialImageLightboxComponent, {
      context: {
        userCanDelete: false,
        album,
        index: index
      }
    });
  }

  loadMorePosts(): void {
    var endpoint;
    if(this.schoolId) {
      endpoint = this.socialService.getSchoolPosts(this.schoolId, this.posts.length);
    } else {
      endpoint = this.socialService.getPosts(this.posts.length, this.systemId, this.groupId);
    }
    endpoint.subscribe((posts: Post[]) => {
      if (posts.length < 20) this.showLoadMore = false;

      this.updatePostViewProperties(posts);
      this.posts.push.apply(this.posts, posts);
    });
  }


  postTagsChanged(tagsAndCategories) {
    this.newPost.CategoryIds = [];
    this.newPost.TagIds = [];
    tagsAndCategories.forEach((value) => {
      if (value.categoryId) {
        this.newPost.CategoryIds.push(value.categoryId);
      } else if (value.tagId) {
        this.newPost.TagIds.push(value.tagId);
      }
    });
  }

  tagFiltersChanged(tagsAndCategories) {
    const filterData = {
      skip: 0,
      systemId: this.systemId,
      categoryFilters: [],
      tagFilters: []
    };
    tagsAndCategories.forEach((value) => {
      if (value.categoryId) {
        filterData.categoryFilters.push(value.categoryId);
      } else if (value.tagId) {
        filterData.tagFilters.push(value.tagId);
      }
    });

    this.isLoadingPosts = true;
    this.posts = [];
    this.socialService.getPostsFilteredByCategory(filterData).subscribe((posts) => {
      this.posts = posts;
      this.isLoadingPosts = false;
    });
  }

  addCategoryFilter(category) {
    this.addCategory.emit(category);
  }

  addTagFilter(tag) {
    this.addTag.emit(tag);
  }

  removePost(post) {
    this.posts.splice(this.posts.indexOf(post), 1);
  }

  feedTypeChange(type) {
    this.posts = [];
    this.feedCache.posts = null;
    if (type.Id) {
      this.teamId = type.Id
      this.schoolId = null;
    } else if(type === 'School') {
      this.schoolId = this.savedSchoolId;
      this.teamId = null;
    } else {
      this.schoolId = null;
      this.teamId = null;
    }
    this.feedCache.feedType = type.Id ? 'Team' : type;
    this.loadPosts();
  }

  getProfileImage() {
    if(this.authService.isAthlete()) {
      this.dataService.getCurrentAthlete().subscribe(data => {
        this.athlete = data;
        this.dataService.getAthleteProfileData(this.athlete.AthleteID).subscribe(data => {
          this.profileImage = data["ImageURL"];
        });
      });
    } else {
      this.profileImage = this.userData.image;
    }
  }

  removeContentFromNewPost(workout) {
    this.newPost.AttachedContent.splice(this.newPost.AttachedContent.indexOf(workout), 1);
  }

  showSelectWorkoutDialog() {
    this.dialogService.open(SelectWorkoutDialogComponent, {
      context: {}
    }).onClose.subscribe((workout) => {
      if(workout) {
        workout.Type = AttachedContentType.Workout;
        this.newPost.AttachedContent.push(workout);
      }
    });
  }

  previewContent(content) {
    // if(content.Type === AttachedContentType.Workout) {
    //   this.dialogService.open(PreviewWorkoutDialogComponent, {
    //     context: { workout: content }
    //   });
    // } else if(content.Type === AttachedContentType.PracticePlan) {
    //   this.dialogService.open(PreviewPracticePlanDialogComponent, {
    //     context: { practicePlan: content }
    //   });
    // }
  }

}
