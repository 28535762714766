<div *ngIf="attachments.length >= 1" class="attached-content" [class.reversed]="reversedStyling">

    <!-- Individual Attachments -->
    <div *ngIf="!groupAttachments || attachments.length < 2" style="display: flex; flex-wrap: wrap;">
        <div class="content-item" *ngFor="let attachment of attachments" (click)="preview(attachment, $event)">
            <nb-icon *ngIf="attachment.Type === AttachedContentType.Workout" pack="rack" icon="dumbbell"></nb-icon>
            <nb-icon *ngIf="attachment.Type === AttachedContentType.PracticePlan" icon="clipboard-outline"></nb-icon>
            <div class="content-title">{{attachment.Name}}</div>
            <nb-icon *ngIf="canEdit" icon="close-circle-outline" (click)="removeContentItem(attachment)"
                nbTooltip="Remove" nbTooltipPlacement="bottom"></nb-icon>
            <nb-icon *ngIf="canDownload" icon="cloud-download-outline" nbTooltip="Download" nbTooltipPlacement="bottom"
                (click)="download([attachment])"></nb-icon>
        </div>
    </div>

    <!-- Grouped Attachments -->
    <div *ngIf="groupAttachments && attachments.length > 1" style="display: flex; flex-wrap: wrap;">
        <div class="content-item" (click)="showSelectAttachmentDialog($event)">
            <nb-icon icon="cube-outline"></nb-icon>
            <div class="content-title">{{attachments.length}} Attachments</div>
            <nb-icon *ngIf="canDownload" icon="cloud-download-outline" nbTooltip="Download All"
                nbTooltipPlacement="bottom" (click)="download(attachments)"></nb-icon>
        </div>
    </div>
</div>