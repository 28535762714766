import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { CordovaService } from './cordova.service';
import { FeedCacheService } from './feed-cache.service';
import { ExerciseOptionsService } from './exercise-options.service';
import * as moment from 'moment';

export class StationLock {
  public isLocked: boolean;
  public allowAthleteChange: boolean;
  public groups: any[];


  constructor() {
    this.isLocked = false;
    this.allowAthleteChange = false;
    this.groups = [];
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl: string = environment.baseUrl;
  public stationLock: StationLock;
  private testEntryAccess: boolean;
  private athleteHasTeamManager: boolean = null;
  private hasProPlanAccess: boolean;
  private jwtHelper: JwtHelperService;
  public stationLockChanged: EventEmitter<StationLock> = new EventEmitter<StationLock>();
  constructor(private http: HttpClient, private cordovaService: CordovaService,
    private feedCache: FeedCacheService, private exerciseOptions: ExerciseOptionsService) {
    this.stationLock = undefined;
    this.jwtHelper = new JwtHelperService();
  }

  public login(username: string, password: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Accept', 'application/json');
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    const body = `&grant_type=password&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&client_id=rackCoachMobile`;

    return this.http.post(this.baseUrl + '/token', body, { headers: headers });
  }

  public refreshAccessToken() {
    const refreshToken = localStorage.getItem('refresh_token');
    const headers = new HttpHeaders();
    headers.set('Accept', 'application/json');
    headers.set('Content-Type', 'application/x-www-form-urlencoded');

    const schoolId = this.getUserData().schoolId;
    const body = `&grant_type=refresh_token&refresh_token=${refreshToken}&client_id=rackCoachMobile&targetSchool=${schoolId}`;

    return this.http.post(this.baseUrl + '/token', body, { headers: headers });
  }

  public logout() {
    try {
      this.cordovaService.unregisterFirebase();
    } catch {}
    this.feedCache.clear();
    this.athleteHasTeamManager = null;
    this.exerciseOptions.exerciseOptionsTable = null;
    this.hasProPlanAccess = null;
    localStorage.clear();
  }

  public requestPasswordReset(username: string): Observable<any> {
    return this.http.post(this.baseUrl + '/api/AccountApi/ResetPasswordRequest', {UserName: username});
  }

  public resetPassword(resetData) {
    return this.http.post(this.baseUrl + '/api/Account/ResetPassword', resetData);
  }

  public storeToken(token: string) {
    this.feedCache.clear();
    window.localStorage.setItem('access_token', token);
    try {
      this.cordovaService.registerFirebase();
    } catch {}
    this.hasProPlanAccess = null;
    this.hasProPlan().subscribe();
  }

  public storeRefreshToken(refreshToken: string) {
    window.localStorage.setItem('refresh_token', refreshToken);
    window.localStorage.setItem('refresh_token_expiry', moment().add(30, 'day').toISOString());
  }

  public getToken(): string {
    return window.localStorage.getItem('access_token');
  }

  public getUserData() {
    const decodedToken = this.jwtHelper.decodeToken(this.getToken());
    return decodedToken;
  }

  public getUserRoles(): string[] {
    const userData = this.getUserData();
    return userData.roles.split(',');
  }

  public isAthlete(): boolean {
    return this.getUserRoles().indexOf('Athlete') > -1;
  }

  public isCoachOfSchool(schoolId: number): Observable<any> {
    return this.http.get(this.baseUrl + '/api/SocialApi/UserIsCoachOfSchool?schoolId=' + schoolId);
  }

  public isAdmin() {
    const userRoles = this.getUserRoles();
    for(let role of userRoles) {
      if(role === 'Admin') return true;
    }
    return false;
  }

  public requestConfirmEmail(email: string) {
    return this.http.get(this.baseUrl + '/api/AccountApi/RequestConfirmationEmail?email=' + encodeURIComponent(email));
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    if (!token) return false;
    const isTokenExpired = this.jwtHelper.isTokenExpired(token);
    if (isTokenExpired) {
      return !this.isRefreshTokenExpired();
    }
    return true;
  }

  public isRefreshTokenExpired(): boolean {
    const refreshTokenExpiryString = localStorage.getItem('refresh_token_expiry');
    if (!refreshTokenExpiryString) return true;
    const refreshTokenExpiry = moment(refreshTokenExpiryString);
    return moment().isAfter(refreshTokenExpiry);
  }

  public isAccessTokenExpired(): boolean {
    const token = this.getToken();
    if (!token) return true;
    return this.jwtHelper.isTokenExpired(token);
  }

  public selectSchoolAndUpdateToken(schoolId: Number, schoolName: string, imageUrl: string, bannerUrl: string) {
    window.localStorage.setItem('school-id', String(schoolId));
    window.localStorage.setItem('school-name', schoolName);
    window.localStorage.setItem('school-image', imageUrl);
    window.localStorage.setItem('school-banner', bannerUrl);
    return this.http.get(this.baseUrl + '/api/AccountApi/SelectSchoolAndExchangeToken?schoolId=' + schoolId);
  }

  public getAvailableSubscriptions(): Observable<any> {
    return this.http.get(this.baseUrl + '/Account/GetAvailableSubscriptions');
  }

  public hasTeamManager(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      if(this.athleteHasTeamManager !== null) {
        observer.next(this.athleteHasTeamManager);
        observer.complete();
      } else {
        this.http.get<boolean>(this.baseUrl + '/api/Message/HasTeamManager?isAthlete=' + this.isAthlete()).subscribe(result => {
          this.athleteHasTeamManager = !!result;
          observer.next(this.athleteHasTeamManager);
          observer.complete();
        });
      }
    });
  }

  public setStationLock(locked, allowChange, groups) {
    var stationLock = new StationLock();
    stationLock.isLocked = locked;
    stationLock.allowAthleteChange = allowChange;
    stationLock.groups = groups;

    localStorage.setItem('station-lock', JSON.stringify(stationLock));
    this.stationLock = stationLock;
    this.stationLockChanged.emit(this.stationLock);
  }

  public getStationLock() {
    return new Observable<any>(observer => {
      if(this.stationLock === undefined || this.stationLock === null) {
        var val = localStorage.getItem('station-lock');
        if(val === undefined || val === null) {
          this.setStationLock(false, false, []);
        } else {
          this.stationLock = JSON.parse(val);
        }
        observer.next(this.stationLock);
        observer.complete();
      } 
      else {
        observer.next(this.stationLock);
        observer.complete();
      }
    });
  }

  public getAthleteInviteInfo(inviteCode): Observable<any> {
    return this.http.get(this.baseUrl + '/api/AccountApi/GetAthleteInviteInfo?inviteCode=' + inviteCode);
  }
  
  public hasUserAgreedToTerms2021(): Observable<any> {
    return this.http.get(this.baseUrl + '/api/AccountApi/HasUserAgreedToTerms2021');
  }

  public agreeToTerms2021(): Observable<any> {
    return this.http.get(this.baseUrl + '/api/AccountApi/AgreeToTerms2021');
  }

  public athleteRegister(regData) {
    return this.http.post(this.baseUrl + '/api/RosterApi/RegisterAthlete', regData);
  }

  public hasProPlan(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      if(this.hasProPlanAccess !== undefined && this.hasProPlanAccess != null) {
        observer.next(this.hasProPlanAccess);
        observer.complete();
      } else {
        this.http.get<boolean>(this.baseUrl + '/api/AccountApi/HasProPlan').subscribe(hasPlan => {
          this.hasProPlanAccess = hasPlan;
          observer.next(hasPlan);
          observer.complete();
        });
      }
    });
  }

}
