import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ngx-athlete-registration-form',
  templateUrl: './athlete-registration-form.component.html',
  styleUrls: ['./athlete-registration-form.component.scss']
})
export class AthleteRegistrationFormComponent implements OnInit {
  @Input() @Output() regInfo: any;

  constructor() { }

  ngOnInit(): void {
  }

}
