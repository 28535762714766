<div class="system" (click)="goToSystem()">
    <div class="system-thumbnail archivo-narrow-700">
        <img [src]="system.thumbnailUrl">
        <div class="availability" *ngIf="showAvailability" [class.restricted]="system.availability != 'Public'">{{system.availability.toUpperCase()}}</div>
    </div>
    <div class="system-card">
        <div class="system-info">
            <div class="lesson-count subheading">{{system.lessonCount}} Lessons</div>
            <div class="title">{{system.title}}</div>
            <div class="speaker subheading">{{system.speaker}} <span style="font-style: normal">|</span> Released {{system.yearReleased}}</div>
            <div *ngIf="system.availability == 'Private'" class="number-of-users">{{system.shareCount}} Users</div>
            <div *ngIf="system.availability != 'Private'" class="number-of-users">{{system.enrolledCount}} Enrolled</div>
        </div>
        <div *ngIf="showControls" class="system-controls">
            <button *ngIf="system.availability == 'Private'" nbButton status="basic" (click)="share($event)"><nb-icon icon="person-add-outline"></nb-icon></button>
            <button (click)="edit()" nbButton status="basic"><nb-icon icon="edit-outline"></nb-icon></button>
            <button nbButton status="basic" (click)="delete($event)">
                <nb-icon icon="trash-outline"></nb-icon>
            </button>
        </div>
    </div>
</div>
