<div (click)="colorPickCircleClicked()" *ngIf="!showAsIcon && !openByDefault" class="color-pick-circle" [style.backgroundColor]="color"></div>
<button *ngIf="showAsIcon && !openByDefault" mat-icon-button class="small-button" (click)="colorPickCircleClicked()">
    <nb-icon icon="color-palette-outline">colorize</nb-icon>
</button>

<div style="display: none;" #advancedPicker
    [style.backgroundColor]="color"
    [(colorPicker)]="color"
    (colorPickerChange)="advancedColorChanged($event)"></div>
<div *ngIf="colorPickerShown" class="default-color-picker">
    <color-block
        [color]="color"
        (onChangeComplete)="onColorChanged($event)"
        [colors]="defaultColors"></color-block>
    <div class="advanced-button" (click)="showAdvanced()">Advanced</div>
</div>
