
<div class="page-header">
    <nb-icon class="back-button" (click)="back()" icon="chevron-left-outline"></nb-icon>
    <div *ngIf="!loading">{{workout ? workout.ProgramName : 'Attached Workouts'}}</div>
</div>

<div class="page-content" [nbSpinner]="loading">
    <nb-card *ngIf="!loading">
        <nb-card-body>
            <div *ngIf="workout">
                <div *ngFor="let slide of workout.Slides; let i = index">
                    <div class="group-heading">
                        <div class="letter">Group {{slide.GroupLetter}}</div>
                    </div>
    
                    <!-- Rotational -->
                    <div *ngIf="slide.DatabaseSlideTypeID === 4">
                        <div *ngFor="let exercise of slide.Exercises; let k = index" class="exercise">
                            <div *ngIf="k <= slide.RotationCount">
                                <div class="title">
                                    {{slide.ExercisesList[k]}}
                                </div>
                                <div *ngFor="let set of [].constructor(exercise.Sets); let index = index" 
                                      class="set">
                                <div class="set-number">Set {{index + 1}}</div>
                                <div class="set-details">
                                    <div class="value">{{exercise.Reps[index] ? exercise.Reps[index]
                                                                                                : exercise.Reps[
                                                                                                    exercise.Reps.length - 1]}}
                                    rep{{exercise.Reps[index] === 1 || (!exercise.Reps[index] && exercise.Reps[exercise.Reps.length - 1]) ? '' : 's'}}
                                    </div>
                                    <div class="value">{{exercise.Percentages.length === exercise.Sets ? exercise.Percentages[index] 
                                                                                                    : exercise.Percentages[0]}}%</div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Sequential -->
                    <div *ngIf="slide.DatabaseSlideTypeID === 1" class="exercise">
                        <div *ngFor="let exercise of slide.ExercisesList" class="set">
                            <div class="set-number">{{exercise}}</div>
                        </div>
                    </div>
    
                    <!-- Station -->
                    <div *ngIf="slide.DatabaseSlideTypeID === 6">
                        <div *ngFor="let station of slide.Stations">
                            <div *ngFor="let exercise of station.Exercises" class="exercise">
                                <div class="title">
                                    {{getExerciseName(exercise)}}
                                  </div>
                                  <div *ngFor="let set of [].constructor(exercise.Sets); let index = index" 
                                        class="set">
                                    <div class="set-number">Set {{index + 1}}</div>
                                    <div class="set-details">
                                      <div class="value">{{exercise.Reps[index] ? exercise.Reps[index]
                                                                                                   : exercise.Reps[
                                                                                                      exercise.Reps.length - 1]}}
                                        rep{{exercise.Reps[index] === 1 || (!exercise.Reps[index] && exercise.Reps[exercise.Reps.length - 1]) ? '' : 's'}}
                                      </div>
                                      <div class="value">{{exercise.Percentages.length === exercise.Sets ? exercise.Percentages[index] 
                                                                                                       : exercise.Percentages[0]}}%</div>
                                    </div>
                                </div>
    
                       
                                <!-- <div>
                                    <div *ngIf="exercise.HasReps">
                                        <div>REP</div>
                                        <div *ngFor="let rep of exercise.Reps">{{rep}}</div>
                                    </div>
    
                                    <div *ngIf="!exercise.HasReps">{{exercise.Notes1}}</div>
    
                                    <div *ngIf="exercise.HasPercentages">
                                        <div>%</div>
                                        <div *ngFor="let percentage of exercise.Percentages">{{percentage}}</div>
                                    </div>
    
                                    <div *ngIf="!exercise.HasPercentages">{{exercise.Notes2}}</div>
    
                                    <div>{{exercise.Notes3}}</div>
                                </div> -->
                            </div>
                        </div>
                    </div>
    
                    <!-- Freeform -->
                    <div *ngIf="slide.DatabaseSlideTypeID === 5">
                        <div [innerHtml]="slide.CustomNote" style="margin-top: 15px;"></div>
                    </div>
                </div>
            </div>
            <div *ngIf="!workout && workouts.length > 1">
                <div class="workout" *ngFor="let workout of workouts" (click)="selectWorkout(workout)">
                    <nb-icon icon="attach-outline"></nb-icon>
                    <div class="workout-name">{{workout.WorkoutName}}</div>
                </div>
            </div>
        </nb-card-body>
    </nb-card>
</div>

<div class="page-footer">
    <button [disabled]="loading" 
            nbButton status="primary" 
            (click)="downloadWorkouts()">
                Download {{!workout ? 'All' : ''}}
    </button>
</div>

