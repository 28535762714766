import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { HEADER_MENU_ITEMS } from './header-menu';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { User } from '../../../@core/data/users';
import { NotificationService } from '../../../services/notification.service';
import { NotificationModel } from '../../../@core/data/notification';
import { SignalrService } from '../../../services/signalr.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  menu = HEADER_MENU_ITEMS;
  showProfileButton: boolean = false;

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  logoffSubscription: Subscription;

  currentTheme = 'default';

  userMenu = [ { title: 'Profile', link: '/profile' }, { title: 'Log out' } ];

  notifications: NotificationModel[];
  notificationsLastViewed: Date;

  stationLocked: boolean = false;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private authService: AuthService,
              public router: Router,
              private notificationService: NotificationService,
              public signalrService: SignalrService) {

    // Athlete Menu Items
    if (this.authService.isAthlete()) {
      this.showProfileButton = true;
    // Coach Menu Items
    } else {
      this.showProfileButton = false;
    }

    this.authService.getStationLock().subscribe(lock => {
      if(lock?.isLocked) {
        this.stationLocked = true;
      }
    });
    this.authService.stationLockChanged.subscribe(lock => {
      this.stationLocked = lock.isLocked;
    });
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.user = this.authService.getUserData();

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.logoffSubscription = this.menuService.onItemClick().pipe(
          filter(({ tag }) => tag === 'user-menu'),
          map(({ item: { title } }) => title),
          filter(title => title === 'Log out')
       ).subscribe(() => {
          this.authService.logout();
          this.router.navigate(['/auth/login']);
     }
    );

    this.notificationService.getNotifications().subscribe((data: any) => {
      this.notifications = data.notifications;
      this.notificationsLastViewed = data.lastViewed;
    });
  }

  ngOnDestroy() {
    this.logoffSubscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  toggleTheme() {
    if (this.currentTheme === 'default') this.currentTheme = 'dark';
    else this.currentTheme = 'default';
    this.themeService.changeTheme(this.currentTheme);
  }

  goToMessages() {
    this.router.navigate(['/messages']);
  }

  goToProfile() {
    this.router.navigate(['/profile']);
  }

}
