import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbCheckboxModule,
  NbThemeModule,
  NbCardModule,
  NbSpinnerModule,
  NbInputModule,
  NbDialogModule,
  NbAutocompleteModule,
  NbTagModule,
  NbPopoverModule,
  NbAlertModule,
  NbBadgeModule,
  NbAccordionModule,
  NbChatModule,
  NbButtonGroupModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';

import {
  FooterComponent,
  HeaderComponent
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  SafePipe
} from './pipes';
import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { DARK_THEME } from './styles/theme.dark';
import { SocialPostComponent } from './components/social-post/social-post.component';
import { MomentModule } from 'ngx-moment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { YesNoDialogComponent } from './components/yes-no-dialog/yes-no-dialog.component';
import { SocialFeedComponent } from './components/social-feed/social-feed.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { FileDropperComponent } from './components/file-dropper/file-dropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FileCropperDialogComponent } from './components/file-cropper-dialog/file-cropper-dialog.component';
import { SchoolAutocompleteComponent } from './components/school-autocomplete/school-autocomplete.component';
import { CoachAutocompleteComponent } from './components/coach-autocomplete/coach-autocomplete.component';
import { CategoryTagInputComponent } from './components/category-tag-input/category-tag-input.component';
import { FilterBarComponent } from './components/filter-bar/filter-bar.component';
import { SystemCardComponent } from './components/system-card/system-card.component';
import { LessonCardComponent } from './components/lesson-card/lesson-card.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SelectDialogComponent } from './components/select-dialog/select-dialog.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { AthleteRegistrationFormComponent } from './components/athlete-registration-form/athlete-registration-form.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { SocialImageLightboxComponent } from './components/social-image-lightbox/social-image-lightbox.component';
import { UserCardOptionsDialogComponent } from './components/user-card/user-card-options-dialog/user-card-options-dialog.component';
import { SocialPostOptionsDialogComponent } from './components/social-post/social-post-options-dialog/social-post-options-dialog.component';
import { AutoAnimateComponent } from './components/auto-animate.component';
import { HudlEmbedComponent } from './components/hudl-embed/hudl-embed.component';
import { EmbedWrapperComponent } from './components/embed-wrapper/embed-wrapper.component';
import { SelectWorkoutDialogComponent } from './components/select-workout-dialog/select-workout-dialog.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ColorPickerDialogComponent } from './components/color-picker-dialog/color-picker-dialog.component';

import { ColorPickerModule } from 'ngx-color-picker';
import { ColorSketchModule } from 'ngx-color/sketch';
import { ColorSwatchesModule } from 'ngx-color/swatches';
import { ColorBlockModule } from 'ngx-color/block';
import { ComposeNotesDialogComponent } from './components/compose-notes-dialog/compose-notes-dialog.component';
import { ChatComponent } from './components/chat/chat.component';
import { ChatMessageComponent } from './components/chat-message/chat-message.component';
import { AttachedContentComponent } from './components/attached-content/attached-content.component';
import { ChooseOptionDialogComponent } from './components/choose-option-dialog/choose-option-dialog.component';
import { PreviewWorkoutDialogComponent } from './components/preview-workout-dialog/preview-workout-dialog.component';
import { WorkoutOutlineDialogComponent } from './components/workout-outline-dialog/workout-outline-dialog.component';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbAccordionModule,
  NbCheckboxModule,
  NbEvaIconsModule,
  NbCardModule,
  NbSpinnerModule,
  NbInputModule,
  NbDialogModule,
  NbAutocompleteModule,
  NbChatModule,
  NbTagModule,
  NbPopoverModule,
  NbAlertModule,
  NbBadgeModule,
  NbButtonGroupModule,
  MatAutocompleteModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatInputModule,
  ColorPickerModule,
  ColorSketchModule,
  ColorSwatchesModule,
  ColorBlockModule,

];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  SocialPostComponent,
  SocialFeedComponent,
  FileDropperComponent,
  SchoolAutocompleteComponent,
  CoachAutocompleteComponent,
  CategoryTagInputComponent,
  FilterBarComponent,
  SystemCardComponent,
  LessonCardComponent,
  UserCardComponent,
  CheckboxComponent,
  AthleteRegistrationFormComponent,
  AppHeaderComponent,
  AutoAnimateComponent,
  EmbedWrapperComponent,
  ColorPickerComponent,
  ColorPickerDialogComponent,
  ChatComponent, 
  ChatMessageComponent,
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  SafePipe
];

@NgModule({
  imports: [CommonModule, ...NB_MODULES, MomentModule, FormsModule, ImageCropperModule],
  exports: [CommonModule, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES, YesNoDialogComponent,
    ErrorDialogComponent, FileCropperDialogComponent, CoachAutocompleteComponent, NotificationsComponent, SelectDialogComponent, CheckboxComponent, SocialImageLightboxComponent, UserCardOptionsDialogComponent, SocialPostOptionsDialogComponent, HudlEmbedComponent, SelectWorkoutDialogComponent, ComposeNotesDialogComponent, AttachedContentComponent, ChooseOptionDialogComponent, PreviewWorkoutDialogComponent, WorkoutOutlineDialogComponent,
  ]
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'dark'
          },
          [ DARK_THEME, DEFAULT_THEME ]
        ).providers
      ]
    };
  }
}
