<input #autoInput
        matInput
        fullWidth
        type="text"
        placeholder="Search Schools"
        [formControl]="formControl"
        [matAutocomplete]="auto"
        [(ngModel)]="selected.name"
        *ngIf="auto" />

<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChange($event)">
    <mat-option class="coach-option" (click)="onOptionClick(item)" *ngFor="let item of filtered$ | async" [value]="item">
        {{ item.name }} | {{item.location}}
    </mat-option>
</mat-autocomplete>