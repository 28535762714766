<div class="scrollable" #scrollable>
  <div class="messages">
    <ng-content select="app-chat-message"></ng-content>
    <p class="no-messages" *ngIf="!messages?.length">{{ noMessagesPlaceholder }}</p>
  </div>
</div>
<div class="form">
  <div class="post-editor-actions" style="margin-left: 15px">
    <!-- <nb-icon pack="rack" icon="dumbbell" (click)="onAttachClicked()"></nb-icon> -->
  </div>
  <ng-content select="nb-chat-form"></ng-content>
</div>