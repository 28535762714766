import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { User } from '../../../@core/data/users';
import { AuthService } from '../../../services/auth.service';
import { FollowService } from '../../../services/follow.service';
import { SocialService } from '../../../services/social.service';
import { ToastService } from '../../../services/toast.service';
import { UserCardOptionsDialogComponent } from './user-card-options-dialog/user-card-options-dialog.component';

@Component({
  selector: 'ngx-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {
  userData: any;
  @Input() user;
  @Input() showAdminActions: boolean = false;
  isFollowing: boolean;
  isSelf: boolean;
  @Input() removeText: string;
  @Input() roleChangeText: string;
  @Input() showMakeOwner: boolean = false;
  @Output() onRemove = new EventEmitter();
  @Output() onRoleChange = new EventEmitter();
  @Output() onMakeOwner = new EventEmitter();
  numOfFollowers: number = 0;

  constructor(private socialService: SocialService, private notificationService: ToastService,
    private followService: FollowService, public authService: AuthService, private dialogService: NbDialogService) {
     }

  ngOnInit(): void {
    this.userData = this.authService.getUserData();
    this.socialService.getAllFollows().subscribe((follows) => {
      follows["users"].find(item => item === this.user.UserId) ? this.isFollowing = true : this.isFollowing = false;
    });
    this.socialService.getFollowers(this.user.UserId).subscribe((followers) => {
      this.numOfFollowers = followers.users.length;
    });
  }

  showUserCardOptions() {
    this.dialogService.open(UserCardOptionsDialogComponent, {
      context: {
        roleChangeText: this.roleChangeText,
        showMakeOwner: this.showMakeOwner,
        removeText: this.removeText
      }
    }).onClose.subscribe((result) => {
        if(!result) {
          return;
        } else if(result === "role-change") {
          this.onRoleChange.emit('');
        } else if(result === "make-owner") {
          this.onMakeOwner.emit('');
        } else if(result === "remove") {
          this.onRemove.emit('');
        }
    });
  }

  toggleFollow() {
    this.socialService.toggleFollowUser(this.user.UserId).subscribe((followDto) => {
      this.ngOnInit();
    }, (e) => {
      console.error(e);
      this.notificationService.error('There was a problem following the user');
    });
  }

}
