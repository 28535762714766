import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Post, PostComment } from '../@core/data/social-types';
import { OrgSchoolSearch } from '../@core/data/org-school';

@Injectable({
  providedIn: 'root'
})
export class SocialService {
  private baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) { }

  public getPosts(skip: Number, systemId: Number, groupId: Number): Observable<Post[]> {
    return this.http.get<Post[]>(this.baseUrl + `/api/SocialApi/Posts?skip=${skip.toString()}` +
      `&systemId=${systemId}&groupId=${groupId}`);
  }

  public getSchoolPosts(id, skip): Observable<any> {
    return this.http.get(this.baseUrl+'/api/SocialApi/SchoolPosts?schoolId=' + id + '&skip=' + skip);
  }

  public getTeamPosts(id, skip): Observable<any> {
    return this.http.get(this.baseUrl + '/api/SocialApi/TeamPosts?teamId=' + id + '&skip=' + skip);
  }

  public getGroupPosts(groupId: Number, skip: Number): Observable<any> {
    return this.http.get(this.baseUrl + '/api/SocialApi/GroupPosts?groupId=' + groupId + '&skip=' + skip);
  }

  public getCoursePosts(courseId: Number, skip: Number): Observable<Post[]> {
    return this.http.get<Post[]>(this.baseUrl + `/api/SocialApi/CoursePosts?courseId=${courseId}&skip=${skip.toString()}`)
  }

  public getPostsFilteredByCategory(data): Observable<Post[]> {
    return this.http.post<Post[]>(this.baseUrl + `/api/SocialApi/Posts`, data);
  }

  public getUserPosts(id: Number, skip: Number): Observable<Post[]> {
    return this.http.get<Post[]>(this.baseUrl + `/api/SocialApi/GetUserPosts?skip=${skip.toString()}&userId=${id.toString()}`);
  }

  public toggleLike(data): Observable<any> {
    return this.http.post(this.baseUrl + '/api/SocialApi/LikePost', data);
  }

  public toggleCommentLike(data): Observable<any> {
    return this.http.post(this.baseUrl + '/api/SocialApi/LikePostComment', data);
  }

  public createPost(post): Observable<Post> {
    return this.http.post<Post>(this.baseUrl + '/api/SocialApi/AddPost', post);
  }

  public createPostComment(data: PostComment): Observable<PostComment> {
    return this.http.post<PostComment>(this.baseUrl + '/api/SocialApi/AddPostComment', data);
  }

  public deletePost(id): Observable<any> {
    return this.http.delete(this.baseUrl + '/api/SocialApi/DeletePost?id=' + id);
  }

  public editPost(post: Post): Observable<any> {
    return this.http.post(this.baseUrl + '/api/SocialApi/EditPost', post);
  }

  public deleteComment(id): Observable<any> {
    return this.http.delete(this.baseUrl + '/api/SocialApi/DeleteComment?id=' + id);
  }

  public editComment(comment: PostComment): Observable<any> {
    return this.http.post(this.baseUrl + '/api/SocialApi/EditComment', comment);
  }

  public searchSchools(queryString: string): Observable<OrgSchoolSearch[]> {
    return this.http.post<OrgSchoolSearch[]>(this.baseUrl + '/api/SchoolApi/SearchOrgSchools', {terms: queryString});
  }

  public getAllFollows(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + `/api/SocialApi/GetAllFollows`);
  }

  public toggleFollowUser(userId) {
    return this.http.post(this.baseUrl + `/api/SocialApi/ToggleFollowUser?id=${userId}`, null);
  }

  public getFollowers(userId): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/api/SocialApi/GetFollowers?userId=${userId}`);
  }

  public getAthleteSchools() {
    return this.http.get(this.baseUrl+'/api/SocialApi/GetAthleteSchools');
  }

  public searchCoaches(queryString: string, maxResults: number = 0): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + '/api/SocialApi/SearchCoaches', {
      SearchTerms: queryString,
      Take: maxResults
    });
  }

  public deletePostWorkout(postWorkoutId: Number, postId: Number) {
    return this.http.delete(`${this.baseUrl}/api/SocialApi/DeletePostWorkout?postWorkoutId=${postWorkoutId}`);
  }

  public getFullPostWorkout(id): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/api/WorkoutApi/FullPostWorkout?id=${id}`);
  }

  public downloadPostWorkout(workoutId: number) {
    return this.http.get(this.baseUrl + '/api/WorkoutApi/DownloadPostWorkout?id=' + workoutId);
  }

  public addPostImage(postId, fileData) {
    let data = new FormData();
    data.append("file", fileData);

    return this.http.post(`${this.baseUrl}/api/SocialApi/AddPostImage?postId=${postId}`, data);
  }

  public deletePostImage(postImageId) {
    return this.http.delete(`${this.baseUrl}/api/SocialApi/DeletePostImage?postImageId=${postImageId}`);
  }

}
