import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

export enum OptionsType {
  Standard = 1,
  AttachedContent = 2
}

@Component({
  selector: 'ngx-choose-option-dialog',
  templateUrl: './choose-option-dialog.component.html',
  styleUrls: ['./choose-option-dialog.component.scss']
})
export class ChooseOptionDialogComponent implements OnInit {
  title = "";
  description = "";
  optionsType = OptionsType.Standard;
  selectMultiple = false;
  options = [];
  selectedOption: any;
  OptionsType = OptionsType;

  constructor(public ref: NbDialogRef<ChooseOptionDialogComponent>) { }

  ngOnInit(): void {
  }

  cancel() {
    this.ref.close();
  }

}
