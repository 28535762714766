import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SocialService } from './social.service';

@Injectable({
  providedIn: 'root'
})
export class FollowService {
  private followedUsers: any[] = null;

  constructor(private socialService: SocialService) { }

  public isUserFollowed(userId: number): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      if (this.followedUsers === null) {
        this.socialService.getAllFollows().subscribe((follows) => {
          this.followedUsers = follows['users'];
          observer.next(this.isUserFollowedHelper(userId));
          observer.complete();
        }, (e) => {
          console.error(e);
          observer.error(e);
          observer.complete();
        });
      } else {
        observer.next(this.isUserFollowedHelper(userId));
        observer.complete();
      }
    });
  }

  private isUserFollowedHelper(userId): boolean {
    // We can assume follows is populated at this point
    const followedUser = this.followedUsers.find(item => item.followedUserId === userId);
    return !!followedUser;
  }
}
