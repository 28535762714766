<div class="form-control-group" width="100%">
    <div class="label-with-link" *ngIf="isFilter">
        <label class="field-name label">CATEGORIES & TAGS</label>
        <div class="is-optional"></div>
    </div>
    <nb-tag-list class="borderless" (tagRemove)="onTagRemove($event)">
        <nb-tag [class.primary]="isFilter" [class.secondary]="!isFilter" *ngFor="let tag of tags" [text]="tag.name" removable></nb-tag>
        <input 
            type="text" 
            #autoInput 
            nbTagInput 
            (input)="onInputChange()"
            (focus)="onFocus($event)"
            [placeholder]="isFilter ? 'Filter By...' : 'Add Category/Tag...'"
            [matAutocomplete]="auto"
            fullWidth
            *ngIf="auto">
    </nb-tag-list>
</div>

<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChange($event)">
    <mat-option (click)="onOptionClick(coach)" *ngFor="let tag of filteredTags$ | async" [value]="tag">
        {{tag.name}}
    </mat-option>
</mat-autocomplete>

