import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { MessageService } from '../../../services/message.service';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'ngx-workout-outline-dialog',
  templateUrl: './workout-outline-dialog.component.html',
  styleUrls: ['./workout-outline-dialog.component.scss']
})
export class WorkoutOutlineDialogComponent implements OnInit {
  workout: any;
  workouts: any[];
  loading: boolean = false;
  alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  constructor(protected dialogRef: NbDialogRef<WorkoutOutlineDialogComponent>,
    private messageService: MessageService,
    private toastService: ToastService) { }

  ngOnInit(): void {
    if (this.workouts.length === 1) {
      this.getMessageWorkout(this.workouts[0]);
    }
  }

  getMessageWorkout(workoutMetadata) {
    this.loading = true;
    this.messageService.getMessageWorkout(workoutMetadata).subscribe(jsonResult => {
      this.loading = false;
      const workout = jsonResult.Data;
      workout.MessageProgramId = workoutMetadata.MessageProgramId;
      if (workout.Slides.length > 0 && (workout.Slides[0].DatabaseSlideTypeID == 1006 || workout.Slides[0].DatabaseSlideTypeID == 7)) {
        workout.Slides.shift();
      }
      for (var i = 0; i < workout.Slides.length; i++) {
        workout.Slides[i].GroupLetter = this.alphabet[i];
      }
      this.workout = workout;
    }, e => {
      console.error(e);
      this.loading = false;
      this.toastService.error('There was a problem getting the workout');
    });
  }

  getExerciseName(exercise) {
    if (exercise?.ExerciseName) {
      return exercise.ExerciseName;
    }
    return '';
  }

  bootstrapColumnSeperate(data, numPerColumn, max) {
    var result = [];
    var rm = 0;
    if (max > data.length)
      rm = data.length;
    else rm = max;
    for (var i = 0; i < rm; i += numPerColumn) {
      var row = [];
      for (var j = 0; j < numPerColumn; ++j) {
        if (data[i + j]) {
          row.push(data[i + j]);
        }
      }
      result.push(row);
    }
    return result;
  }

  getSlideTypeName(slide) {
    let name = '';

    switch (slide.DatabaseSlideTypeID) {
      case 1:
        name = 'sequential';
        break;
      case 4:
        name = 'rotational';
        break;
      case 5:
        name = 'freeform';
        break;
      case 6:
        name = 'station';
        break;
      default:
        break;
    }

    return name;
  }

  downloadWorkouts() {
    if (this.workout) {
      this.downloadWorkout(this.workout);
      return;
    }

    let downloadWorkoutRecursive = (index) => {
      let workout = this.workouts[index];
      this.messageService.downloadWorkout(workout.MessageProgramId).subscribe(result => {
        if (index === this.workouts.length - 1) {
          this.loading = false;
          this.toastService.success('Workouts downloaded and saved to the default folder');
          this.dialogRef.close();
        } else {
          downloadWorkoutRecursive(index + 1);
        }
      }, e => {
        this.loading = false;
        this.toastService.error('There was a problem downloading the workouts');
        console.error(e);
      });
    }

    this.loading = true;
    downloadWorkoutRecursive(0);

  }

  downloadWorkout(workout) {
    this.loading = true;
    this.messageService.downloadWorkout(workout.MessageProgramId).subscribe(result => {
      this.loading = false;
      this.toastService.success('Workout downloaded and saved to the default folder');
      this.dialogRef.close();
    }, e => {
      this.loading = false;
      this.toastService.error('There was a problem downloading the workout');
      console.error(e);
    });
  }

  back() {
    this.workout = null;
    this.dialogRef.close();
  }

  selectWorkout(workout) {
    this.getMessageWorkout(workout);
  }

}

