import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ImageTransform } from 'ngx-image-cropper';
import { ConstantService } from '../../../services/constant.service';

@Component({
  selector: 'ngx-file-cropper-dialog',
  templateUrl: './file-cropper-dialog.component.html',
  styleUrls: ['./file-cropper-dialog.component.scss']
})
export class FileCropperDialogComponent implements OnInit {
  image: any;
  cropOptions: any;
  croppedImageData: any;
  transform: ImageTransform = {};
  scale = 1;

  constructor(protected dialogRef: NbDialogRef<FileCropperDialogComponent>,
              public constantService: ConstantService) {
  }

  ngOnInit(): void {
  }

  onCrop(event) {
    this.croppedImageData = event.base64;
  }

  crop() {
    this.dialogRef.close(this.croppedImageData);
  }

  cancel() {
    this.dialogRef.close(null);
  }

  zoomOut() {;
    if(this.scale === 0.1) { return; }
    this.scale = Number((this.scale -= .1).toFixed(1));
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
  }

  zoomIn() {
    if(this.scale === 1) { return; }
    this.scale = Number((this.scale += .1).toFixed(1));
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
  }

  zoomReset() {
    this.scale = Number((this.scale = 1).toFixed(1));
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
  }

}
