import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FeedCacheService {
  public posts: any[];
  public feedType: string;
  public refreshFeed: EventEmitter<any> = new EventEmitter();

  constructor() { }

  clear() {
    this.posts = null;
    this.feedType = null;
  }
}
