<nb-card *ngIf="showTagFilter">
    <nb-card-body style="padding: 0.7rem">
        <ngx-category-tag-input [systemId]="systemId" [isFilter]="true" (tagsChanged)="tagFiltersChanged($event)"
            [addCategory]="addCategory" [addTag]="addTag"></ngx-category-tag-input>
    </nb-card-body>
</nb-card>

<!-- Compose Post -->
<nb-card *ngIf="allowPosting" [nbSpinner]="posting" class="compose-post-card">
    <!-- Current User Info -->
    <nb-card-header>
        <nb-user
            [name]="userData.fullName"
            [picture]="profileImage"
            [title]="userData.title"></nb-user>
    </nb-card-header>

    <!-- Post Text -->
    <nb-card-body style="padding: 0;">
        <textarea class="post-textarea" fullWidth nbInput placeholder="Write a New Post" 
            [(ngModel)]="newPost.Content"></textarea>
    </nb-card-body>

    <!-- Attached Content -->
    <nb-card-body class="social-post-attached-content" *ngIf="newPost.AttachedContent.length > 0">
        <div class="content-item" *ngFor="let content of newPost.AttachedContent" (click)="previewContent(content)">
            <nb-icon *ngIf="content.Type === AttachedContentType.Workout" icon="dumbbell" pack="rack"></nb-icon>
            <nb-icon *ngIf="content.Type === AttachedContentType.PracticePlan" icon="clipboard-outline"></nb-icon>
            <div class="content-title">{{content.Name}}</div>
            <nb-icon icon="close-circle-outline"
                     (click)="removeContentFromNewPost(content)"
                     nbTooltip="Remove"
                     nbTooltipPlacement="bottom">
            </nb-icon>
        </div>
    </nb-card-body>

    <!-- Attached Images -->
    <nb-card-body class="social-post-attached-content" *ngIf="newPost.Images.length > 0">
        <div class="attached-image" *ngFor="let image of newPost.Images">
            <img [src]="image.Url" (click)="openImage(image)" />
            <div class="icon-wrapper">
                <nb-icon icon="close-circle-outline"
                         *ngIf="image.Url"
                         (click)="removePostImage(image)"
                         nbTooltip="Remove Image" 
                         nbTooltipPlacement="bottom"></nb-icon>
            </div>
        </div>
    </nb-card-body>

    <!-- Post Actions -->
    <nb-card-footer style="display: flex; justify-content: space-between; flex-wrap: wrap;">
        <div style="display: flex">
            <div class="post-editor-actions" *ngIf="isCoach">
                <div class="upload-img-btn">
                    <nb-icon icon="image-outline" nbTooltip="Attach Image" nbTooltipPlacement="bottom">
                    </nb-icon>
                    <input type="file"
                        nbInput 
                        title=" "
                        accept="image/*" 
                        [multiple]="true" 
                        #thumbnail
                        (change)="postImageUploaded($event)"
                        nbTooltip="Upload Image" nbTooltipPlacement="bottom">      
                </div>
                <nb-icon icon="dumbbell" pack="rack" (click)="showSelectWorkoutDialog()"></nb-icon>
                <!-- <nb-icon icon="clipboard-outline" nbTooltip="Attach Practice Plan" nbTooltipPlacement="bottom"></nb-icon>
                <nb-icon icon="calendar-outline" nbTooltip="Attach Event" nbTooltipPlacement="bottom"></nb-icon>
                <nb-icon icon="attach-outline" nbTooltip="Attach File" nbTooltipPlacement="bottom"></nb-icon>
                <span class="glyphter-text-format" nbTooltip="Format Text" nbTooltipPlacement="bottom"></span>
                <span class="glyphter-emoji" nbTooltip="Emojis" nbTooltipPlacement="bottom"></span> -->
            </div>
            <span *ngIf="systemId && !groupId && !courseId" style="width: auto">
                <ngx-category-tag-input [systemId]="systemId" 
                    (tagsChanged)="postTagsChanged($event)" [clearTags]="clearTags"></ngx-category-tag-input>
            </span>
            <span *ngIf="(categories || tags) && !groupId && !courseId" style="width: auto">
                <ngx-category-tag-input [categoryList]="categories" [tagList]="tags"
                    (tagsChanged)="postTagsChanged($event)" [clearTags]="clearTags"></ngx-category-tag-input>
            </span>
        </div>


        <div style="display: flex;">
            <nb-checkbox *ngIf="groupId && showIncludeInMainFeed" 
                        class="checkbox" [checked]="includeInMainFeed" 
                        (checkedChange)="toggleIncludeInMainFeed($event)"
                        [disabled]="posting">
                <label class="checkbox-label">Display in Community Feed</label>
            </nb-checkbox>
            <button size="small" nbButton 
                status="primary"
                [disabled]="posting"
                (click)="createPost()">Post</button>
        </div>

    </nb-card-footer>
</nb-card>

<!-- Social Posts -->
<div style="min-height: 50px" [nbSpinner]="isLoadingPosts" nbSpinnerStatus="primary">
    <ngx-social-post *ngFor="let post of posts" [userData]="userData" [post]="post"
        (addCategoryFilter)="addCategoryFilter($event)" (addTagFilter)="addTagFilter($event)" 
        (onDelete)="removePost(post)" [showOrigin]="groupId ? false : true"
        [allowPosting]="allowPosting && ((isCoach || post.PostPrivacy?.SchoolId) || groupId || courseId)">
    </ngx-social-post>
    <div *ngIf="showLoadMore" style="width: 100%; display: flex; justify-content: center;">
        <button *ngIf="posts && posts.length > 0" nbButton (click)="loadMorePosts()">Load More</button>
    </div>

</div>
