import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss']
})
export class FilterBarComponent implements OnInit {
  @Input() newItemLink: string;
  @Input() newItemName: string;
  @Input() placeholder: string;
  @Input() showSpeaker: boolean = false;
  @Input() useDebounce: boolean = true;
  @Output() filterChanged: EventEmitter<any> = new EventEmitter();

  searchTerms: string = '';
  selectedSpeaker: any;

  timeout: any;
  debounceTime: number = 500;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  filter() {
    if(!this.useDebounce) {
      this.filterChanged.emit({
        searchTerms: this.searchTerms,
        selectedSpeaker: this.selectedSpeaker
      });
      return;
    }
    const later = () => {
      this.timeout = null;
      this.filterChanged.emit({
        searchTerms: this.searchTerms,
        selectedSpeaker: this.selectedSpeaker
      });
    };
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(later, this.debounceTime);
  }

  newItem() {
    this.router.navigate([this.newItemLink]);
  }

}
