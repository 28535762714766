<div class="header-container">
    <div class="logo-container">
        <a class="logo" href="/">
            <div></div>
        </a>
    </div>
</div>

<div class="header-container">
    <!-- Messages -->
    <div *ngIf="!stationLocked" (click)="goToMessages()">
        <nb-icon [status]="router.url === '/messages' ? 'primary' : ''" icon="paper-plane-outline"></nb-icon>
        <nb-badge class="unread-messages-badge" *ngIf="signalrService.unreadMessages > 0" [text]="signalrService.unreadMessages" status="danger"></nb-badge>
    </div>

    <!-- Profile -->
    <div *ngIf="showProfileButton" style="margin-left: 20px; margin-top: 3px;">
        <nb-icon (click)="goToProfile()" [status]="router.url === '/profile' ? 'primary' : ''" pack="rack" icon="person-circle"></nb-icon>
    </div>
</div>