import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.scss']
})
export class SelectDialogComponent implements OnInit {
  items: any[];
  filteredItems: any[];
  filterString: string = '';
  showImage: boolean;
  heading: string = 'Select an item';

  constructor(protected dialogRef: NbDialogRef<SelectDialogComponent>) { }

  ngOnInit(): void {
    this.filteredItems = this.items;
  }

  selectItem(item) {
    this.dialogRef.close(item);
  }

  back() {
    this.dialogRef.close(null);
  }

  filterStringChanged(event) {
    this.filterString = event.searchTerms;
    this.filter();
  }

  filter() {
    const lowerFilter = this.filterString.toLowerCase();
    this.filteredItems = this.items.filter(
      (c) => this.filterString.length === 0 || c.title.toLowerCase().includes(lowerFilter)
    );
  }
}
