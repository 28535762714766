import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { CordovaService } from '../../../services/cordova.service';
import { SignalrService } from '../../../services/signalr.service';

@Component({
  selector: 'ngx-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

  showProfileButton: boolean = false;
  userPictureOnly: boolean = false;
  user: any;
  stationLocked: boolean = false;
  unreadMessageCount: number = 0;
  notificationsLastViewed: Date;
  isAndroid: boolean = false;
  hasMessenger: boolean = false;

  constructor(public router: Router,
              public signalrService: SignalrService,
              private authService: AuthService,
              private cordovaService: CordovaService) {

    this.isAndroid = this.cordovaService.getPlatform() === "android";

    // Athlete Menu Items
    if (this.authService.isAthlete()) {
      this.showProfileButton = true;
      this.authService.hasTeamManager().subscribe((result) => {
        this.hasMessenger = result;
      });
    // Coach Menu Items
    } else {
      this.showProfileButton = false;
      this.hasMessenger = true;
    }
    this.authService.getStationLock().subscribe(lock => {
      if(lock?.isLocked) {
        this.stationLocked = true;
      }
    });
    this.authService.stationLockChanged.subscribe(lock => {
      this.stationLocked = lock.isLocked;
    });
    if(!this.signalrService.connection) {
      this.signalrService.connect();
    }
    this.signalrService.onMessageReceived.subscribe((message) => {
      this.unreadMessageCount++;
    })
  }

  ngOnInit() {

    this.user = this.authService.getUserData();

    /* const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe(themeName => this.currentTheme = themeName);
 */

  }

  adjustBadgeSize() {
    var defaultClass: string = 'unread-messages-badge';
    if (!this.showProfileButton) {
      defaultClass = defaultClass + ' no-profile-btn';
    }
    var addClass = '';
    if (this.signalrService.unreadMessages >= 10) {
      addClass = ' double-digit-badge';
    }
    return defaultClass + addClass
  }

  goToHome() {
    this.router.navigate(['/']);
  }

  goToMessages() {
    this.router.navigate(['/messages']);
  }

  goToProfile() {
    this.router.navigate(['/profile']);
  }

}
