import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

export interface CreateLessonData {
  Id: number;
  Title: string;
  Speaker: string;
  SpeakerSchool: string;
  SpeakerPosition: string;
  VideoUrl: string;
  VideoLength?: number;
  Content: string;
  Tags: string[];
  Categories: string[];
}

export interface CreateSectionData {
  id: number;
  title: string;
  lessons: any[];
  editing: boolean;
  expanded: boolean;
  lessonsToRemove: number[];
}

export interface CreateSystemData {
  id: number;
  title: string;
  availability: string;
  speaker: string;
  speakerSchool: string;
  speakerPosition: string;
  currentCategory: string;
  currentTag: string;
  categories: string[];
  tags: string[];
  thumbnail: File;
  thumbnailUrl: any;
  background: File;
  backgroundUrl: any;
  otherMaterials: File[];
  materials: any[];
  materialsToDelete: number[];
  sections: CreateSectionData[];
  content: string;
  sectionsToRemove: number[];
}

@Injectable({
  providedIn: 'root'
})
export class SystemService {
  private baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  public createLesson(data: CreateLessonData): Observable<any> {
    return this.http.post(this.baseUrl + '/api/SystemApi/CreateLesson', data);
  }

  public getLessonCategories(): Observable<any> {
    return this.http.get(this.baseUrl + '/api/SystemApi/GetLessonCategories');
  }

  public getLessons(): Observable<any> {
    return this.http.get(this.baseUrl + '/api/SystemApi/GetLessons');
  }

  public deleteLesson(id): Observable<any> {
    return this.http.get(this.baseUrl + `/api/SystemApi/DeleteLesson?id=${id}`);
  }

  public searchLessonsByName(name): Observable<any> {
    return this.http.get(this.baseUrl +  `/api/SystemApi/SearchLessonsByName?name=${name}`);
  }

  public createSystem(data): Observable<any> {
    return this.http.post(this.baseUrl + `/api/SystemApi/CreateSystem`, data);
  }

  public getSystems(): Observable<any> {
    return this.http.get(this.baseUrl + '/api/SystemApi/GetSystems');
  }

  public adminGetSystems(): Observable<any> {
    return this.http.get(this.baseUrl + '/api/SystemApi/AdminGetSystems');
  }

  public getSystemFromTitle(title, isAdmin = false): Observable<any> {
    let url = '/api/SystemApi/GetSystemFromTitle';
    if (isAdmin)
      url = '/api/SystemApi/GetSystemFromTitleAdmin';

    return this.http.get(this.baseUrl + `${url}?title=${title}`);
  }

  public getLessonFromTitle(title, sy, se): Observable<any> {
    return this.http.get(this.baseUrl + `/api/SystemApi/GetLessonFromTitle?title=${title}&sy=${sy}&se=${se}`);
  }

  public deleteSystem(id): Observable<any> {
    return this.http.get(this.baseUrl + `/api/SystemApi/DeleteSystem?id=${id}`);
  }

  public uploadSystemMedia(id, data): Observable<any> {
    return this.http.post(this.baseUrl + `/api/SystemApi/UploadSystemMedia?id=${id}`, data);
  }

  public startSystem(systemId): Observable<any> {
    return this.http.get(this.baseUrl + `/api/SystemApi/StartSystem?systemId=${systemId}`);
  }

  public markLessonComplete(systemId, sectionId, lessonId): Observable<any> {
    return this.http.get(this.baseUrl + `/api/SystemApi/MarkLessonComplete?systemId=${systemId}&sectionId=${sectionId}&lessonId=${lessonId}`);
  }

  public updateSystemUserShares(data): Observable<any> {
    return this.http.post(this.baseUrl + `/api/SystemApi/UpdateSystemUserShares`, data);
  }

  public getSystemUserShares(systemId): Observable<any> {
    return this.http.get(this.baseUrl + `/api/SystemApi/GetSystemUserShares?systemId=${systemId}`);
  }

  public getSystemCategoriesAndTags(systemId, filter): Observable<any> {
    return this.http.get(this.baseUrl + `/api/SystemApi/GetSystemCategoriesAndTags?systemId=${systemId}&filter=${filter}`);
  }

  public getLibraryItems(filters): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + `/api/SystemApi/GetLibraryItems`, filters);
  }

  public getLibraryCategories(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + '/api/SystemApi/GetLibraryCategories');
  }

  public changeVimeoEmbed(videoLink) {
    if (!videoLink) return null;
    const pattern1 = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
    const match = videoLink.match(pattern1);
    let videoSrc;
    if (match) {
      const vimeoEmbedLink = 'https://player.vimeo.com/video/' + match[5];
      videoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(vimeoEmbedLink);
    } else {
      videoSrc = undefined;
    }
    return videoSrc;
  }
}
