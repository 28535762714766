import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { ToastService } from "../../services/toast.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private zone: NgZone, private toastService: ToastService) {}

  handleError(error: Error) {

    this.zone.run(() =>{
        //this.toastService.error('Whoops, something went wrong...')
    });

    console.error("Error from global error handler", error);
  }
}
