import { User } from './users';

export enum GroupPrivacyLevel {
    Public = 1,
    Closed = 2,
    Private = 3
}

export enum GroupAccessLevel {
    Organizer = 1,
    Member = 2,
    Creator = 3
}

export interface Group {
    Id: number;
    Name: string;
    Description: string;
    ImageId: number | null;
    BannerImageId: number | null;
    CreatedBy: number;
    PrivacyLevel: GroupPrivacyLevel;
    DateCreated: string;
    DateDeleted: string;
    InviteRequiredAccessLevel: GroupAccessLevel;
    UploadRequiredAccessLevel: GroupAccessLevel;
    BannerUrl: string;
    ThumbnailUrl: string;
    Tags: CategoryTagDTO[];
    Categories: CategoryTagDTO[];
    Members: GroupUser[];
    Organizers: GroupUser[];
    PendingInvites: GroupUser[];
    AdminUser: User;
    CurrentUserAccessLevel: GroupAccessLevel;
    CurrentUserIsMember: boolean;
    GroupImages: GroupImage[];
    GroupDocuments: GroupDocument[];
    CurrentUserHasUploadAccess: boolean;
    CurrentUserHasInviteAccess: boolean;
}

export interface GroupUser {
    Id: number;
    GroupId: number;
    UserId: number;
    AccessLevel: GroupAccessLevel;
    AddedByUserId: number;
    InviteAccepted: boolean;
    RequestedMembership: boolean;
    DateCreated: string;
    User: User;
}

export interface CategoryTagDTO {
    categoryId: number | null;
    tagId: number | null;
    name: string;
}


export interface GroupImage {
    Id: number;
    GroupId: number;
    ImageId: number;
    CreatedBy: number;
    ImageUrl: string;
    DateCreated: string;
}

export interface GroupDocument {
    Id: number;
    GroupId: number;
    DocumentId: number;
    CreatedBy: number;
    FileName: string;
    Url: string;
    DateCreated: string;
}

export interface GroupAnnouncement {
    Id: number;
    GroupId: number;
    Title: string;
    Text: string;
    CreatedBy: number;
    DateCreated: string;
}

export interface GroupEvent {
    Id: number;
    GroupId: number;
    Title: string;
    Url: string;
    CreatedBy: number;
    TakeAttendance: boolean;
    StartDate: string;
    EndDate: string;
    DateCreated: string;
}

export interface GroupEventAttendance {
    Id: number;
    GroupEventId: number;
    IsAttending: boolean;
    UserId: number;
    User: User;
}

export interface GroupPoll {
    Id: number;
    GroupId: number;
    Title: string;
    Description: string;
    CreatedBy: number;
    RankedChoice: boolean;
    AnonymousVoting: boolean;
    Published: boolean;
    MembersCanNominate: boolean;
    ClosingDate: string;
    DateCreated: string;
    Nominations: GroupPollNomination[];
    Votes: GroupPollVote[];
}

export interface GroupPollNomination {
    Id: number;
    GroupPollId: number;
    Title: string;
    Description: string;
    ImageId: number | null;
    ImageUrl: string;
    CreatedBy: number;
    DateCreated: string;
}

export interface GroupPollVote {
    Id: number;
    GroupPollId: number;
    Voter: number;
    FirstChoice: number;
    SecondChoice: number | null;
    ThirdChoice: number | null;
    LastVoted: string;
}
