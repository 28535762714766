export class Post implements IPost {
    Id: Number;
    Content: string;
    UserId: Number;
    PostTypeId: Number;
    PostCategoryId?: Number;
    SchoolId?: Number;
    TeamId?: Number;
    OrganizationId?: Number;
    Title: string;
    DateCreated?: string;
    Comments: PostComment[];
    Likes: Like[];
    ImageUrls: string[] = [];
    Images?: any[] = [];
    User: any;
    PostPrivacy?: PostPrivacy;
    IsLikedByCurrentUser: boolean;
    ShowAllComments: boolean = false;
    NewCommentContent: string = '';
    SystemId?: Number;
    GroupId?: Number;
    GroupName?: string;
    GroupImageUrl?: string;
    PostAsGroup: boolean;
    CourseId?: Number;
    TagIds: Number[];
    CategoryIds: Number[];
    Tags: string[];
    Categories: string[];
    IncludeInMainFeed?: boolean;
    AttachedContent?: any[];
    PostWorkouts?: any[];
    ImageFiles?: any[] = [];
    
    constructor() {
        this.Content = '';
    }
}
interface IPost {
    Id: Number;
    Content: string;
    UserId: Number;
    PostTypeId: Number;
    PostCategoryId?: Number;
    SchoolId?: Number;
    TeamId?: Number;
    OrganizationId?: Number;
    Title: string;
    DateCreated?: string;
    Comments: PostComment[];
    Likes: Like[];
    ImageUrls: string[];
    User: any;
    PostPrivacy?: PostPrivacy;
    IsLikedByCurrentUser: boolean;
    SystemId?: Number;
    GroupId?: Number;
}


export class PostComment implements IPostComment {
    Id: Number;
    UserId: Number;
    PostId: Number;
    Content: string;
    DateCreated?: string;
    Likes: Like[];
    User: any;
    IsLikedByCurrentUser: boolean;
    editing: boolean = false;
    menuItems: any[] = [];
    contentCopy: string;
    constructor(postId: Number) {
        this.Content = '';
        this.PostId = postId;
        this.menuItems = [];
        this.editing = false;
    }
}
interface IPostComment {
    Id: Number;
    UserId: Number;
    PostId: Number;
    DateCreated?: string;
    Likes: Like[];
    User: any;
    IsLikedByCurrentUser: boolean;
}

export interface Like {
    Id: Number;
    UserId: Number;
    DateCreated?: Date;
    UserDisplayName: string;
}

export interface PostPrivacy {
    Id: number;
    OrganizationId: number | null;
    SchoolId: number | null;
    NetworkGroupId: number | null;
    FollowersOnly: boolean;
    FollowedUsersOnly: boolean;
    UsersOnly: string;
}

export enum AttachedContentType {
    Workout = 1,
    PracticePlan = 2
}