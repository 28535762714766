import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'hudl-embed',
  templateUrl: './hudl-embed.component.html',
  styleUrls: ['./hudl-embed.component.scss']
})
export class HudlEmbedComponent implements OnInit, AfterViewInit {
  VIDEO_ASPECT_RATIO: number = 9.0 / 16;

  @Input() url: string;
  safeUrl: SafeResourceUrl;
  playerHeight: number = 0;

  @ViewChild('iframe') iframe: ElementRef;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    if(!this.url) {
      return;
    }

    const url = new URL(this.url);
    let stringUrl = `//www.hudl.com/embed${url.pathname}`;
    if(this.url.includes('/v/')) {
      return;
    }

    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(stringUrl);
  }

  ngAfterViewInit(): void {
    this.setPlayerHeight();
  }

  setPlayerHeight(): void {
    const elem = this.iframe?.nativeElement;
    if(!elem) {
      return;
    }

    this.playerHeight = elem.clientWidth * this.VIDEO_ASPECT_RATIO;
  }

  onResize(event: Event): void {
    this.setPlayerHeight();
  }

}
