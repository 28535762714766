import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { NbIconLibraries, NbThemeService } from '@nebular/theme';
import { LightboxConfig } from 'ngx-lightbox';
import { AuthService } from './services/auth.service';
import { CordovaService } from './services/cordova.service';
import { ExerciseOptionsService } from './services/exercise-options.service';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, AfterViewInit {

  constructor(private iconLibraries: NbIconLibraries, private _lightboxConfig: LightboxConfig,
    private cordovaService: CordovaService, private exerciseOptionsService: ExerciseOptionsService,
    private themeService: NbThemeService, private router: Router, private authService: AuthService) {
    _lightboxConfig.centerVertically = true;
    this.iconLibraries.registerFontPack('rack', { iconClassPrefix: 'ra' });
    this.iconLibraries.registerSvgPack('/assets/mdi.svg', { iconClassPrefix: 'mdi' });
    if (this.authService.isAuthenticated() && !this.authService.isAthlete()) {
      this.exerciseOptionsService.loadExercisesForCoach();
    }
  }

  ngOnInit(): void {
    let theme = window.localStorage.getItem('theme');
    if (theme) {
      this.themeService.changeTheme(theme);
    } else theme = 'dark';

    const statusBar: any = window['StatusBar'];
    const platform = window['cordova']?.platformId;
    if (statusBar) {
      setTimeout(() => {
        if (theme === 'default') {
          statusBar.styleDefault();
          statusBar.backgroundColorByHexString('#FFFFFF');
        } else {
          statusBar.styleLightContent();
          statusBar.backgroundColorByHexString('#15191c');
        }
      }, platform === 'ios' ? 1500 : 5000);
    }
    const keyboard = window['Keyboard'];
    // Handle iOS body tag scrolling on input focus
    if (platform === 'ios' && keyboard) {
      window.addEventListener('keyboardDidShow', function () {
        if (!this.isScrolledIntoView(document.activeElement)) {
          document.activeElement.scrollIntoView();
          document.body.style.overflow = 'scroll';
          document.scrollingElement.scrollTop -= 10;
          document.scrollingElement.scrollLeft = 0;
          setTimeout(()=>document.body.style.overflow = 'hidden', 10);

        }
      });
      window.addEventListener('keyboardWillHide', function () {
        document.body.style.overflow = 'scroll';
        document.scrollingElement.scrollTop = 0;
        document.scrollingElement.scrollLeft = 0;
        setTimeout(() => document.body.style.overflow = 'hidden', 10);
      });
    }

    if (this.cordovaService.queuedMessageThread) {
      this.goToMessage();
    }
    this.cordovaService.onMessageTap.subscribe(() => {
      this.goToMessage();
    });
  }

  goToMessage() {
    if(this.cordovaService.queuedMessageThread) {
      this.router.navigate(['/message'], { state: {
        threadId: this.cordovaService.queuedMessageThread
      }});
      this.cordovaService.queuedMessageThread = null;
    }
  }

  isScrolledIntoView(el) {
    const rect = el.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    const isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    return isVisible;
  }

  ngAfterViewInit() {
    document.addEventListener('pause', this.onPause.bind(this), false);
    document.addEventListener('resume', this.onResume.bind(this), false);
    document.addEventListener('offline', this.offline.bind(this), false);
    document.addEventListener('online', this.online.bind(this), false);
    document.addEventListener('backbutton', (e) => {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }, false);
    this.cordovaService.setFirebaseEvents();
  }

  onPause() {
    this.cordovaService.onPause.emit('');
  }

  onResume() {
    this.cordovaService.onResume.emit('');
  }

  online() {
    this.cordovaService.online.emit('');
  }

  offline() {
    this.cordovaService.offline.emit('');
  }
}
