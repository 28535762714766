<div class="form-control-group text-field">
    <span class="label-with-link">
        <label class="label">Find a Coach</label>
        <div></div>
    </span>
    <div style="width: 100%" [class.has-text]="inputHasText()">
        <input type="text"
            #autoInput
            style="width: 100%"
            placeholder="Search for a coach"
            name="find-coach"
            nbInput
            aria-label="Number"
            [formControl]="formControl"
            [matAutocomplete]="auto"
            *ngIf="auto">
            
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChange($event)">
          <mat-option class="coach-option" (click)="onOptionClick(coach)" *ngFor="let coach of filteredCoaches$ | async" [value]="coach">
            <div class="coach-option-container">
                <div class="coach-name">{{ coach.FullName }}</div>
                <div class="school-name">{{ coach.BusinessName }}</div>
            </div>
          </mat-option>
        </mat-autocomplete>
    </div>

</div>