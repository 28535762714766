import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NbTagComponent, NbTagInputAddEvent } from '@nebular/theme';
import { Observable } from 'rxjs';
import { CategoryTagDTO } from '../../../@core/data/group';
import { SystemService } from '../../../services/system.service';

@Component({
  selector: 'ngx-category-tag-input',
  templateUrl: './category-tag-input.component.html',
  styleUrls: ['./category-tag-input.component.scss']
})
export class CategoryTagInputComponent implements OnInit {
  @Input() systemId: number;
  @Input() isFilter: boolean = false;
  @Input() tagList: CategoryTagDTO[];
  @Input() categoryList: CategoryTagDTO[];
  @Input() clearTags: EventEmitter<any>;
  @Input() addCategory: EventEmitter<any>;
  @Input() addTag: EventEmitter<any>;
  @Output() tagsChanged: EventEmitter<Set<any>> = new EventEmitter<Set<any>>();
  allTags: any[] = [];
  tags: Set<any> = new Set();
  filteredTags$: Observable<any[]>;
  @ViewChild('autoInput') input;

  constructor(private systemService: SystemService) { }

  ngOnInit(): void {
    if (this.input) {
      this.input.nativeElement.value = '';
    }
    if (this.systemId) {
      this.systemService.getSystemCategoriesAndTags(this.systemId, '').subscribe((tags) => {
        this.allTags = tags;
      }, (e) => {
        console.error(e);
      });
    } else {
      this.allTags.push.apply(this.allTags, this.tagList);
      this.allTags.push.apply(this.allTags, this.categoryList);
    }

    if (this.clearTags) {
      this.clearTags.subscribe(() => {
        this.tags = new Set();
      });
    }
    if (this.addCategory) {
      this.addCategory.subscribe((categoryName) => {
        let category = null;
        this.allTags.forEach((currentTag) => {
          if (currentTag.categoryId >= 0 && categoryName === currentTag.name) {
            category = currentTag;
          }
        });
        if (category) {
          this.onSelectionChange({ option: { value: category }});
        }
      });
    }
    if (this.addTag) {
      this.addTag.subscribe((tagName) => {
        let tag = null;
        this.allTags.forEach((currentTag) => {
          if (currentTag.tagId >= 0 && tagName === currentTag.name) {
            tag = currentTag;
          }
        });
        if (tag) {
          this.onSelectionChange({ option: { value: tag }});
        }

      });
    }
  }

  onTagRemove(tagToRemove: NbTagComponent): void {
    const tagText = tagToRemove.text;
    let toRemove = null;
    this.tags.forEach((tag) => {
      if (tag.name === tagText) {
        toRemove = tag;
      }
    });
    this.tags.delete(toRemove);
    this.tagsChanged.emit(this.tags);
  }

  getFiltered(value: string, useClick: boolean): Observable<any[]> {
    const retVal = new Observable<any[]>((observer) => {
      observer.next(this.allTags.filter((tag) => tag.name.toLowerCase().includes(value.toLowerCase())));
      observer.complete();
      if (useClick)
        this.input.nativeElement.click();
    });
    return retVal;
  }

  onInputChange() {
    const value = this.input.nativeElement.value;
    this.filteredTags$ = this.getFiltered(value, true);
  }

  onSelectionChange(event) {
    const newTag = event.option.value;
    this.input.nativeElement.value = '';
    if (!this.tags.has(newTag)) {
      this.tags.add(newTag);
      this.tagsChanged.emit(this.tags);
    }
  }

  onFocus(event) {
    if (event.relatedTarget === null) {
      this.filteredTags$ = this.getFiltered('', true);
    }
  }

}
