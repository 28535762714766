import { Component, Input, OnInit } from '@angular/core';
import { NotificationModel } from '../../../@core/data/notification';
import { GroupService } from '../../../services/group.service';
import { NotificationService } from '../../../services/notification.service';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'ngx-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  @Input() notifications: NotificationModel[];

  constructor(private notificationService: NotificationService,
              private groupService: GroupService,
              private toastService: ToastService) { }

  ngOnInit(): void {

  }

  respondToGroupInvite(accepted: boolean, notification: NotificationModel) {
    if (accepted) {
      this.groupService.acceptInvite(notification.sourceId).subscribe(() => {
        this.toastService.success('Invite Accepted');
      });
    } else {
      this.groupService.declineInvite(notification.sourceId).subscribe(() => {
        this.toastService.success('Invite Declined');
      });
    }

    const index = this.notifications.indexOf(notification);
    this.notifications.splice(index, 1);
  }

}
