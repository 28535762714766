import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-color-picker-dialog',
  templateUrl: './color-picker-dialog.component.html',
  styleUrls: ['./color-picker-dialog.component.scss']
})
export class ColorPickerDialogComponent implements OnInit {
  @Input() color: string;
  @Input() top: number = 0;
  @Input() left: number = 0;
  @Output() colorChanged = new EventEmitter<string>();

  constructor(protected dialogRef: NbDialogRef<ColorPickerDialogComponent>) { }

  ngOnInit(): void {
  }

  onColorChanged(e) {
    this.colorChanged.emit(e);
  }

}
