import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit {
  @Input() color: string;
  @Input() closeColorPicker: EventEmitter<any>;
  @Input() showAsIcon: boolean = false;
  @Input() openByDefault: boolean = false;
  @Output() colorChanged: EventEmitter<any> = new EventEmitter();

  colorPickerShown: boolean = false;
  @ViewChild('advancedPicker') advancedPicker;

  defaultColors: string[] = [
    '#0085ff',
    '#ffeb3b',
    '#697689',
    '#37d67a',
    '#FCB900',
    '#9900EF',
    '#F44336',
    '#182A45',
    '#333333',
    '#ffffff'
  ];

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    if(this.closeColorPicker) {
      this.closeColorPicker.subscribe(() => {
        this.colorPickerShown = false;
        this.cdr.detectChanges();
      });
    }

    if(this.openByDefault) {
      this.colorPickerShown = true;
      this.cdr.detectChanges();
    }
  }

  
  onColorChanged(e) {
    this.color = e.color.hex;
    this.colorChanged.emit(this.color);
  }

  colorPickCircleClicked() {
    setTimeout(() => {
      this.colorPickerShown = !this.colorPickerShown;
      this.cdr.detectChanges();
    }, 1);
  }

  showAdvanced() {
    this.colorPickerShown = false;
    this.advancedPicker.nativeElement.click();
    this.cdr.detectChanges();
  }

  advancedColorChanged(e) {
    this.colorChanged.emit(e);
  }

}
