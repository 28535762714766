<nb-card style="width: 90vw">
  <nb-card-body>
    {{text}}
  </nb-card-body>
  <nb-card-footer>
    <div style="text-align: right">
      <button class="cancel" nbButton [status]="noStatus" (click)="no()">{{noText}}</button>
      <button style="margin-left: 7px;" nbButton [status]="yesStatus" (click)="yes()">{{yesText}}</button>
    </div>
  </nb-card-footer>
</nb-card>
