<div class="select-container">
    <div class="page-header">
        <nb-icon class="back-button" (click)="back()" icon="chevron-left-outline"></nb-icon>
        <div class="header-title">
            {{heading}}
        </div>
    </div>
    <div class="page-content no-bottom-nav" style="padding-bottom: 80px">

        <ngx-filter-bar [placeholder]="'Search...'" [useDebounce]="false" (filterChanged)="filterStringChanged($event)"></ngx-filter-bar>

        <nb-card style="margin-top: 15px">
            <nb-card-body class="no-vertical-padding">
                <div *ngFor="let item of filteredItems" class="list-item cursor-pointer" (click)="selectItem(item)">
                    <img class="select-image" *ngIf="showImage" [src]="item.image" />
                    <div>
                        <div>{{item.title}}</div>
                        <div class="subtitle" *ngIf="item.subtitle">{{item.subtitle}}</div>
                    </div>
                    
                </div>
            </nb-card-body>
        </nb-card>


       <!--  <div class="select-item" (click)="selectItem(item)" *ngFor="let item of items">
            <img *ngIf="showImage" [src]="item.image" />
            <div style="display: flex; align-items: center;">
                <div class="title">{{item.title}}</div>
                <div *ngIf="item.subtitle" class="subtitle">{{item.subtitle}}</div>
            </div>
        </div> -->
    </div>
    
</div>
