import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CordovaService } from '../../../services/cordova.service';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout withScroll [class.relative-scroll]="relativeScroll">

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
        <ng-content *ngIf="isActionsShown()" select="nb-actions"></ng-content>
        <ng-content select="nb-menu" class="narrow"></ng-content>

      </nb-layout-column>



    </nb-layout>
  `
})
export class OneColumnLayoutComponent {
  public relativeScroll: boolean = false;

  private actionsHiddenUrls: string[] = [
    '/message',
    '/workout',
    '/workout-timer',
    '/station-mode-workout',
    '/practice/timer',
    '/terms2021'
  ];

  constructor(private router: Router, public cordova: CordovaService) {
    this.relativeScroll = this.cordova.getPlatform() === 'ios';
  }

  public isActionsShown() {
    return this.actionsHiddenUrls.indexOf(this.router.url) < 0;
  }
}
