<div class="page-header">
    <div class="space-between" style="position: relative">
        <div class="header-container">
            <div class="logo-container" (click)="goToHome()">
                <a class="logo">
                    <div></div>
                </a>
            </div>
        </div>
        
        <div class="header-container">
            <!-- Messages -->
            <div *ngIf="!stationLocked && hasMessenger" (click)="goToMessages()">
                <nb-icon [status]="router.url === '/messages' ? 'primary' : ''" icon="message-circle-outline" [style]="(unreadMessageCount > 0) ? 'margin-right: 5px' : ''"></nb-icon>
                <nb-badge [class]="adjustBadgeSize()" *ngIf="unreadMessageCount > 0" [text]="unreadMessageCount" status="danger"></nb-badge>
            </div>
        
            <!-- Profile -->
            <div *ngIf="showProfileButton" style="margin-top: 5px;">
                <nb-icon (click)="goToProfile()" [status]="router.url === '/profile' ? 'primary' : ''" pack="rack" icon="person-circle"></nb-icon>
            </div>
        </div>
    </div>
   
</div>
