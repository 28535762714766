import { Component, Input, OnInit, Optional, Output } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { WorkoutService } from '../../../services/workout.service';

@Component({
  selector: 'ngx-select-workout-dialog',
  templateUrl: './select-workout-dialog.component.html',
  styleUrls: ['./select-workout-dialog.component.scss']
})
export class SelectWorkoutDialogComponent implements OnInit {
  filteredFolders: any[] = [];
  folders: any[] = [];
  loadedFolders = false;
  sharedFolders: any[];
  loadedSharedFolders = false;
  currentSearch = '';
  noSearchMatches = false;
  
  constructor(@Optional() private dialogRef: NbDialogRef<SelectWorkoutDialogComponent>,
              private workoutService: WorkoutService) { }

  ngOnInit(): void {
    this.workoutService.getFolders().subscribe(folders => {
      this.folders.unshift(...folders);
      this.filteredFolders.unshift(...folders);
      this.loadedFolders = true;
    });
    this.workoutService.getSharedFolders().subscribe(folders => {
      this.folders.push(...folders);
      this.filteredFolders.push(...folders);
      this.loadedSharedFolders = true;
    });
  }

  selectWorkout(workout) {
    this.dialogRef.close(workout);
  }

  back() {
    if(this.dialogRef) {
      this.dialogRef.close();
    }
  }

  filterWorkouts(event) {
    const searchTerms = event.searchTerms;
    this.currentSearch = searchTerms;

    const folders = JSON.parse(JSON.stringify(this.folders));

    // filter folders
    this.filteredFolders = folders.filter((folder: any) => {
      let returnVal = true;
      if (searchTerms && searchTerms.length > 0) {
        returnVal = folder.Name.toLowerCase().includes(searchTerms.toLowerCase());
        folder.Workouts.forEach(workout => {
          returnVal = returnVal || workout.Name.toLowerCase().includes(searchTerms.toLowerCase());
        });
      }
      if (!returnVal) { return false; }

      return returnVal;
    });

    // filter workouts
    this.filteredFolders.forEach(folder => {
      folder.Workouts = folder.Workouts.filter((workout: any) => {
        let returnVal = true;
        if (searchTerms && searchTerms.length > 0) {
          returnVal = workout.Name.toLowerCase().includes(searchTerms.toLowerCase());
        }
        if (!returnVal) { return false; }

        return returnVal;
      });
    });

    if(this.filteredFolders.length === 0
       && (this.sharedFolders.length > 0 || this.folders.length > 0)) {
      this.noSearchMatches = true;
    } else {
      this.noSearchMatches = false;
    }
  }

}
