import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ToastService } from '../../../services/toast.service';
import { FileCropperDialogComponent } from '../file-cropper-dialog/file-cropper-dialog.component';

@Component({
  selector: 'ngx-file-dropper',
  templateUrl: './file-dropper.component.html',
  styleUrls: ['./file-dropper.component.scss']
})
export class FileDropperComponent implements OnInit {
  @Input() isImage: boolean = false;
  @Input() cropImage: boolean = false;
  @Input() cropOptions: any = {};
  @Input() multiple: boolean = false;
  @Output() filesChange = new EventEmitter<any>();
  @Input() previewUrl: string | ArrayBuffer;

  constructor(private notificationService: ToastService, private dialogService: NbDialogService) { }

  ngOnInit(): void {
  }

  onChange(event) {
    const files = event.target.files;
    const file = files[0];

    if (this.isImage) {
      const mimeType = files[0].type;
      if (mimeType.match(/image\/*/) == null) {
        this.notificationService.error('Only images are supported.');
        return;
      }
    }

    if (this.cropImage) {
      const dialogContext: any = {
        image: file,
        cropOptions: this.cropOptions
      };

      const dialogRef = this.dialogService.open(FileCropperDialogComponent,
        { context: dialogContext, closeOnBackdropClick: false });

      dialogRef.onClose.subscribe((imageData) => {
        if (!imageData) return;
        const croppedFile = this.dataURLtoFile(imageData, 'newProfilePic.png');
        this.emitChange([croppedFile], croppedFile);
      });
      return;
    }

    this.emitChange(files, file);
  }

  emitChange(files, file) {
    if (this.isImage) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.previewUrl = reader.result;
      };
    }

    this.filesChange.emit(files);
  }

  private dataURLtoFile(dataurl, filename): File {

    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
  }
}
