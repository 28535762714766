<nb-card class="bottom-modal">
    <nb-card-body class="no-vertical-padding">
        <div class="list-item cursor-pointer" (click)="roleChange()">
            <nb-icon style="margin-right: 17px; margin-left: 3px;" icon="people-outline"></nb-icon>
            {{roleChangeText}}
        </div>
        <div class="list-item cursor-pointer" (click)="makeOwner()" *ngIf="showMakeOwner">
            <nb-icon style="margin-right: 17px; margin-left: 3px;" icon="repeat-outline"></nb-icon>
            Make Owner
        </div>
        <div class="list-item cursor-pointer" (click)="remove()">
            <nb-icon style="margin-right: 17px; margin-left: 3px;" icon="close-outline"></nb-icon>
            {{removeText}}
        </div>
    </nb-card-body>
    <nb-card-footer>
        <button class="blue-button" fullWidth nbButton status="primary" (click)="close()">Done</button>
    </nb-card-footer>
</nb-card>