import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { ToastService } from '../../../../services/toast.service';

@Component({
  selector: 'ngx-user-card-options-dialog',
  templateUrl: './user-card-options-dialog.component.html',
  styleUrls: ['./user-card-options-dialog.component.scss']
})
export class UserCardOptionsDialogComponent implements OnInit {
  roleChangeText: string;
  showMakeOwner: boolean;
  removeText: string;

  constructor(protected dialogRef: NbDialogRef<UserCardOptionsDialogComponent>, 
    private dialogService: NbDialogService, private toastService: ToastService) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  roleChange() {
    this.dialogRef.close("role-change");
  }

  makeOwner() {
    this.dialogRef.close("make-owner");
  }

  remove() {
    this.dialogRef.close("remove");
  }

}
