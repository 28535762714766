import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { ToastService } from '../../../services/toast.service';
import { YesNoDialogComponent } from '../yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'ngx-system-card',
  templateUrl: './system-card.component.html',
  styleUrls: ['./system-card.component.scss']
})
export class SystemCardComponent implements OnInit {
  @Input() system: any;
  @Input() showControls: boolean = false;
  @Input() showAvailability: boolean = true;

  @Output() deleteClicked: EventEmitter<any> = new EventEmitter();
  @Output() shareClicked: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  delete(e) {
    e.stopPropagation();
    this.deleteClicked.emit(this.system);
  }

  share(e) {
    e.stopPropagation();
    this.shareClicked.emit(this.system);
  }

  edit() {
    this.router.navigate(['/admin/program-builder/'], {state: {
      system: this.system
    }});
  }

  goToSystem() {
    this.router.navigate(['/programs/' + this.system.urlTitle]);
  }

}
