<div class="page-header">
    <nb-icon class="back-button" (click)="back()" icon="chevron-left-outline"></nb-icon>
    <div>Select Workout</div>
</div>

<div class="page-content" [nbSpinner]="!loadedFolders || !loadedSharedFolders">
    <div style="margin-bottom: 15px">
        <ngx-filter-bar [placeholder]="'Search Workouts'"
                        (filterChanged)="filterWorkouts($event)">
                        </ngx-filter-bar>
    </div>
    
    <nb-accordion *ngFor="let folder of filteredFolders">
        <nb-accordion-item>
            <nb-accordion-item-header>
                <div>{{folder.Name}}</div>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
                <div class="workout-item" *ngFor="let w of folder.Workouts" (click)="selectWorkout(w)">
                    {{w.Name}}
                </div>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
</div>