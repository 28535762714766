<div class="lesson" (click)="goToLesson()">
    <div class="lesson-info">
        <div class="video-length subheading" *ngIf="lesson.videoLength">{{lesson.videoLength}} MIN</div>
        <div class="title">{{lesson.title}}</div>
        <div class="speaker subheading">{{lesson.speaker}}</div>
        <div class="released subheading">Released {{lesson.yearReleased}}</div>
    </div>
    <div *ngIf="showControls" class="lesson-controls">
        <button (click)="edit($event)" nbButton status="basic"><nb-icon icon="edit-outline"></nb-icon></button>
        <button style="margin-left: 5px;" nbButton status="basic" (click)="delete($event)">
            <nb-icon icon="trash-outline"></nb-icon>
        </button>
    </div>
</div>
