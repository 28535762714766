import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Post, PostComment } from '../@core/data/social-types';
import { User } from '../@core/data/users';
import { UserHistory } from '../@core/data/user-history';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) { }

  public getNotifications() {
    return this.http.get(this.baseUrl + '/api/Notification/GetNotifications');
  }

}
