<nb-card class="bottom-modal">
    <nb-card-body class="no-vertical-padding">
        <div class="list-item cursor-pointer" (click)="editPost()" *ngIf="showEdit">
            <nb-icon style="margin-right: 17px; margin-left: 3px;" icon="edit-outline"></nb-icon>
            Edit
        </div>
        <div class="list-item cursor-pointer" (click)="deletePost()" *ngIf="showDelete">
            <nb-icon style="margin-right: 17px; margin-left: 3px;" icon="trash-outline"></nb-icon>
            Delete
        </div>
    </nb-card-body>
    <nb-card-footer>
        <button class="blue-button" fullWidth nbButton status="primary" (click)="close()">Done</button>
    </nb-card-footer>
</nb-card>