<div class="avatar" [style.background-image]="avatarStyle" *ngIf="!reply">
    <ng-container *ngIf="!avatarStyle">
        {{ getInitials() }}
    </ng-container>
</div>

<div class="message" (mouseenter)="onHover(index)" (mouseleave)="onHover(-1)">
    <ng-container [ngSwitch]="type">
        <nb-chat-message-file *ngSwitchCase="'file'"
                            [sender]="sender" [date]="date" [dateFormat]="dateFormat"
                            [message]="messageText" [files]="files">
        </nb-chat-message-file>
        <nb-chat-message-quote *ngSwitchCase="'quote'"
                            [sender]="sender" [date]="date" [dateFormat]="dateFormat"
                            [message]="messageText" [quote]="quote">
        </nb-chat-message-quote>
        <nb-chat-message-map *ngSwitchCase="'map'"
                            [sender]="sender" [date]="date"
                            [message]="messageText" [latitude]="latitude" [longitude]="longitude">
        </nb-chat-message-map>
        <nb-chat-message-text *ngSwitchDefault
                            [sender]="sender" [date]="date" [dateFormat]="dateFormat"
                            [message]="messageText">
        </nb-chat-message-text>
    </ng-container>
    <div class="attached-content-wrapper">
        <ngx-attached-content [attachments]="attachedContent" 
                              [groupAttachments]="true"
                              [canEdit]="false"
                              [canDownload]="true"
                              [reversedStyling]="true">
        </ngx-attached-content>
    </div>
</div>
