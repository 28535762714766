<div class="control-bar">
    <div style="display: flex; width: 100%">
        <div class="search-icon">
            <nb-icon icon="search-outline"></nb-icon>
        </div>
    
        <input tabindex="-1" [(ngModel)]="searchTerms" (keyup)="filter()" class="search" nbInput type="text" [placeholder]="placeholder">
        <input *ngIf="showSpeaker" [(ngModel)]="selectedSpeaker" (keyup)="filter()" class="author" nbInput type="text" placeholder="Speaker">
    </div>

    <button *ngIf="newItemLink" style="margin-left: auto;" nbButton status="primary" (click)="newItem()">{{newItemName}}</button>

</div>
