<div class="page-header">
    <nb-icon class="back-button" (click)="cancel()" icon="chevron-left-outline"></nb-icon>
    <div>{{workout.Name}}</div>
</div>

<div class="page-content">
    <nb-card class="preview-workout-dialog-wrapper">
        <nb-card-body [nbSpinner]="loading">
            <div *ngFor="let timer of workout.FlattenedTimers">
                <div class="group-heading">
                    <div class="letter">Group {{timer.GroupLetter}}</div>
                    <!-- <ng-container [ngSwitch]="timer.TimerType">
                            <ng-container *ngSwitchCase="1">
                                <nb-icon icon="grid-outline"></nb-icon>
                            </ng-container>
                            <ng-container *ngSwitchCase="2">
                                <mat-icon svgIcon="sequential"></mat-icon>
                            </ng-container>
                            <ng-container *ngSwitchCase="3">
                                <mat-icon svgIcon="station"></mat-icon>
                            </ng-container>
                            <ng-container *ngSwitchCase="4">
                                <nb-icon icon="freeform" pack="rack"></nb-icon>
                            </ng-container>
                            <ng-container *ngSwitchCase="undefined">
                                <svg class="bullet-icon" width="18" height="11" viewBox="0 0 18 11" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M8.35829 0C6.97496 0 5.67496 0.55 4.72496 1.44167C4.44996 0.608333 3.67496 0 2.74996 0C1.59996 0 0.666626 0.933333 0.666626 2.08333C0.666626 3.23333 1.59996 4.16667 2.74996 4.16667C2.92496 4.16667 3.09163 4.14167 3.25829 4.1C3.21663 4.30833 3.18329 4.525 3.17496 4.75C3.02496 7.81667 5.63329 10.3167 8.74163 9.975C10.8666 9.74167 12.6416 8.09167 13.0666 6C13.1916 5.40833 13.1916 4.83333 13.1166 4.28333C13.0416 3.78333 13.4333 3.34167 13.9416 3.34167H17.3333V0H8.35829ZM2.74996 2.5C2.51663 2.5 2.33329 2.31667 2.33329 2.08333C2.33329 1.85 2.51663 1.66667 2.74996 1.66667C2.98329 1.66667 3.16663 1.85 3.16663 2.08333C3.16663 2.31667 2.98329 2.5 2.74996 2.5ZM8.16663 7.5C6.78329 7.5 5.66663 6.38333 5.66663 5C5.66663 3.61667 6.78329 2.5 8.16663 2.5C9.54996 2.5 10.6666 3.61667 10.6666 5C10.6666 6.38333 9.54996 7.5 8.16663 7.5Z"
                                        fill="white" />
                                </svg>
                            </ng-container>
                        </ng-container> -->
                </div>
    
                <ng-container [ngSwitch]="timer.TimerType">
                    <!-- Rotational -->
                    <ng-container *ngSwitchCase="1">
                        <div *ngFor="let exercise of timer.Exercises" class="exercise">
                            <div class="title">
                                {{exercise.WorkoutExercise.Exercise?.ExerciseName ?
                                exercise.WorkoutExercise.Exercise.ExerciseName : 'Unnamed'}}
                            </div>
                            <div *ngFor="let set of [].constructor(exercise.WorkoutExercise.Sets); let index = index"
                                class="set">
                                <div class="set-number">Set {{index + 1}}</div>
                                <div class="set-details">
                                    <div class="value">{{exercise.WorkoutExercise.RepList[index] ?
                                        exercise.WorkoutExercise.RepList[index]
                                        : exercise.WorkoutExercise.RepList[
                                        exercise.WorkoutExercise.RepList.length - 1]}}
                                        rep{{exercise.WorkoutExercise.RepList[index] === 1 ||
                                        (!exercise.WorkoutExercise.RepList[index] &&
                                        exercise.WorkoutExercise.RepList[exercise.WorkoutExercise.RepList.length - 1]) ?
                                        '' : 's'}}
                                    </div>
                                    <div class="value">{{exercise.WorkoutExercise.PercentageList.length ===
                                        exercise.WorkoutExercise.Sets ? exercise.WorkoutExercise.PercentageList[index]
                                        : exercise.WorkoutExercise.PercentageList[0]}}%</div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <!-- Sequential -->
                    <ng-container *ngSwitchCase="2" class="exercise">
                        <div *ngFor="let exercise of timer.Exercises; let index = index" class="set">
                            <div class="set-number">
                                {{exercise.WorkoutExercise.Exercise.ExerciseName}}
                            </div>
                        </div>
                    </ng-container>
                    <!-- Station -->
                    <ng-container *ngSwitchCase="3">
                        <div *ngFor="let station of timer.Stations">
                            <div *ngFor="let exercise of station.StationExercises" class="exercise">
                                <div class="title">
                                    {{exercise.WorkoutExercise.Exercise.ExerciseName}}
                                </div>
                                <div *ngFor="let set of [].constructor(exercise.WorkoutExercise.Sets); let index = index"
                                    class="set">
                                    <div class="set-number">Set {{index + 1}}</div>
                                    <div class="set-details">
                                        <div class="value">{{exercise.WorkoutExercise.RepList[index] ?
                                            exercise.WorkoutExercise.RepList[index]
                                            : exercise.WorkoutExercise.RepList[
                                            exercise.WorkoutExercise.RepList.length - 1]}}
                                            rep{{exercise.WorkoutExercise.RepList[index] === 1 ||
                                            (!exercise.WorkoutExercise.RepList[index] &&
                                            exercise.WorkoutExercise.RepList[exercise.WorkoutExercise.RepList.length -
                                            1]) ? '' : 's'}}
                                        </div>
                                        <div class="value">{{exercise.WorkoutExercise.PercentageList.length ===
                                            exercise.WorkoutExercise.Sets ?
                                            exercise.WorkoutExercise.PercentageList[index]
                                            : exercise.WorkoutExercise.PercentageList[0]}}%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <!-- Freeform -->
                    <ng-container *ngSwitchCase="4">
                        <div [innerHtml]="timer.Content" style="margin-top: 15px;"></div>
                    </ng-container>
    
                </ng-container>
    
            </div>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton fullWidth class="cancel-btn" status="basic" (click)="cancel()">Close</button>
            <button nbButton fullWidth status="success" (click)="download()">Download</button>
        </nb-card-footer>
    </nb-card>
</div>