import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-lesson-card',
  templateUrl: './lesson-card.component.html',
  styleUrls: ['./lesson-card.component.scss']
})
export class LessonCardComponent implements OnInit {
  @Input() lesson: any;
  @Input() showControls: boolean = false;

  @Output() deleteClicked: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  edit(e) {
    e.stopPropagation();
    this.router.navigate(['/admin/lesson-builder/'], { state: {
      lesson: this.lesson
    }});
  }

  delete(e) {
    e.stopPropagation();
    this.deleteClicked.emit(this.lesson);
  }

  goToLesson() {
    this.router.navigate(['/programs/topic/' + this.lesson.urlTitle], { state: {
      lesson: this.lesson
    }});
  }

}
