import { Component, OnInit } from '@angular/core';
import { Group } from '../../../@core/data/group';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { ToastService } from '../../../services/toast.service';
import { GroupService } from '../../../services/group.service';
import { YesNoDialogComponent } from '../yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'ngx-social-image-lightbox',
  templateUrl: './social-image-lightbox.component.html',
  styleUrls: ['./social-image-lightbox.component.scss']
})
export class SocialImageLightboxComponent implements OnInit {
  group: Group;
  userCanDelete: boolean;
  album: any[];
  index: number;
  showChevrons: boolean = false;
  loadingImage: boolean = true;

  constructor(protected dialogRef: NbDialogRef<SocialImageLightboxComponent>, private groupService: GroupService,
              private toastService: ToastService, private dialogService: NbDialogService) { }

  ngOnInit(): void {
  }

  onMouseEnterImage() {
    if(this.album.length > 1) { this.showChevrons = true; }
  }
  onMouseLeaveImage() {
    if(this.album.length > 1) { this.showChevrons = false; }
  }

  nextImage() {
    this.loadingImage = true;
    if(this.index < (this.album.length - 1)) {
      this.index += 1;
    } else if(this.index == (this.album.length - 1)) {
      this.index = 0;
    }
  }

  prevImage() {
    this.loadingImage = true;
    if(this.index > 0) {
      this.index -= 1;
    } else if(this.index == 0) {
      this.index = this.album.length - 1;
    }
  }

  closeDialog(){
    this.dialogRef.close();
  }

  removeImage() {
    this.dialogService.open(YesNoDialogComponent, {
      context: { text: 'Would you like to delete this image?' }
    }).onClose.subscribe(yesClicked => {
      if (yesClicked) {
        this.groupService.removeImageFromGroup(this.album[this.index].id, this.group.Id).subscribe(() => {
          this.toastService.success("Successfully deleted image");
          this.album.splice(this.index, 1);
          if(this.album.length > 0 && this.index === this.album.length) {
            this.index -= 1
          } else if(this.album.length === 0) {
            this.dialogRef.close();
          }
        }, (err) => {
          console.error(err);
          this.toastService.error("Error deleting image");
        });
      }
    });
  }

}
