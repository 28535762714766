import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { SocialService } from '../../../services/social.service';

@Component({
  selector: 'ngx-coach-autocomplete',
  templateUrl: './coach-autocomplete.component.html',
  styleUrls: ['./coach-autocomplete.component.scss']
})
export class CoachAutocompleteComponent implements OnInit {
  @ViewChild('autoInput') input;
  formControl: FormControl = new FormControl();
  filteredCoaches$: Observable<any[]>;
  @Output() onSelection = new EventEmitter<any>();
  selected: any;

  timeout: any;
  debounceTime: number = 500;

  constructor(private socialService: SocialService) { }

  ngOnInit(): void {
    this.formControl.valueChanges.subscribe((value) => {
      const later = () => {
        if (typeof(value) === 'string')
        this.filteredCoaches$ = this.getFiltered(value);
      else
        this.formControl.setValue('');
      };
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(later, this.debounceTime);
    });
  }

  getFiltered(value: string): Observable<any[]> {
    if (!value || value.length < 2) {
      return new Observable<any[]>();
    }
    const retVal = new Observable<any[]>((observer) => {
      this.socialService.searchCoaches(value, 10).subscribe((coaches) => {
        observer.next(coaches.slice());
      }, (e) => {
        observer.error(e);
      });
    });
    return retVal;
  }

  onSelectionChange(event) {
    this.onSelection.emit(event.option.value);
  }

  onOptionClick(coach) {
    this.input.nativeElement.value = '';
    this.input.nativeElement.blur();
  }

  inputHasText() {
    return this.formControl.value && this.formControl.value.length >= 0;
  }

}
