import { Component, ElementRef, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { forkJoin } from 'rxjs';
import { Group, GroupAccessLevel } from '../../../@core/data/group';
import { AttachedContentType, Post, PostComment } from '../../../@core/data/social-types';
import { AuthService } from '../../../services/auth.service';
import { DataService } from '../../../services/data.service';
import { GroupService } from '../../../services/group.service';
import { SocialService } from '../../../services/social.service';
import { ToastService } from '../../../services/toast.service';
import { FileCropperDialogComponent } from '../file-cropper-dialog/file-cropper-dialog.component';
import { SelectWorkoutDialogComponent } from '../select-workout-dialog/select-workout-dialog.component';
import { SocialImageLightboxComponent } from '../social-image-lightbox/social-image-lightbox.component';
import { YesNoDialogComponent } from '../yes-no-dialog/yes-no-dialog.component';
import { SocialPostOptionsDialogComponent } from './social-post-options-dialog/social-post-options-dialog.component';

@Component({
  selector: 'ngx-social-post',
  templateUrl: './social-post.component.html',
  styleUrls: ['./social-post.component.scss']
})
export class SocialPostComponent implements OnInit {
  @Input() post: Post;
  @Input() userData: any;
  @Input() allowPosting = true;
  @Input() showOrigin = true;
  @Output() addCategoryFilter: EventEmitter<any> = new EventEmitter();
  @Output() addTagFilter: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @ViewChild('commentBox') commentBox: ElementRef;
  originalPost: Post;
  group: Group;
  currentUser;
  athlete: any;
  profileImage: any;
  showCommentBox: boolean = false;
  newComment: PostComment;
  urls: string[] = [];
  userIsCoachOfSchool: boolean;
  AttachedContentType = AttachedContentType;
  isCoach: boolean;
  imagePreviewGridColumns = 0;
  saving = false;

  editing: boolean = false;
  editMenuItems = [];
  onSavePostCalls: any[] = [];

  postImageCropOptions: any = {
    aspectRatio: 1,
    maintainAspectRatio: true
  };

  constructor(private authService: AuthService, private dataService: DataService, 
    private socialService: SocialService, private groupService: GroupService, 
    private dialogService: NbDialogService, private toastService: ToastService,
    private router: Router) {
    }
    
    ngOnInit(): void {
      this.currentUser = this.authService.getUserData();
      this.isCoach = !this.authService.isAthlete();
      this.profileImage = this.userData.image;
      this.getProfileImage();
      this.newComment = new PostComment(this.post.Id);
      this.setUrls();

      this.post.AttachedContent = [];
      if (!this.post.ImageFiles) { this.post.ImageFiles = []; }
      if (!this.post.ImageUrls) { this.post.ImageUrls = []; }
      for(let workout of this.post.PostWorkouts) {
        workout.Type = AttachedContentType.Workout;
        workout.Name = workout.WorkoutName;
        this.post.AttachedContent.push(workout);
      }

      this.originalPost = JSON.parse(JSON.stringify(this.post));
      this.getNumOfColumnsForImageGrid();

      this.post.Comments.reverse();
      for(let i = 0; i < this.post.Comments.length; i++) {
        const comment = this.post.Comments[i];
        if(comment.User.ProfileImageURL.toLowerCase() === '/images/profileplaceholder.png') {
          comment.User.ProfileImageURL = undefined;
        }
      }

      if(this.post?.User?.ProfileImageURL.toLowerCase() === '/images/profileplaceholder.png') {
        this.post.User.ProfileImageURL = undefined;
      }

      // Set the context menu if applicable
      if(this.post.GroupId) {
        this.groupService.getGroup(this.post.GroupId).subscribe(group => {
          this.group = group;
          this.getPostMenuItems();
        });
      } else if(this.post.SchoolId) {
        this.authService.isCoachOfSchool(Number(this.post.SchoolId)).subscribe(result => {
          this.userIsCoachOfSchool = result;
          this.getPostMenuItems();
        });
      } else {
        this.getPostMenuItems();
      }
    }

    getNumOfColumnsForImageGrid() {
      if (this.post.Images.length === 1) {
        this.imagePreviewGridColumns = 1;
      } else if (this.post.Images.length === 2) {
        this.imagePreviewGridColumns = 2;
      } else {
        this.imagePreviewGridColumns = 3;
      }
    }

    navigateToGroup() {
      this.router.navigate(['/group/' + this.group.Id]);
    }

    getPostMenuItems() {
      if (this.authService.isAdmin() || this.post.UserId.toString() === this.currentUser.userId) {
        this.editMenuItems.push({ title: 'Edit' });
        this.editMenuItems.push({ title: 'Delete' });
      } else if ((this.post.GroupId && (this.group.CurrentUserAccessLevel === (GroupAccessLevel.Creator || GroupAccessLevel.Organizer))) ||
                (this.post.SchoolId && this.userIsCoachOfSchool)) {
        this.editMenuItems.push({ title: 'Delete' });
      }
      this.bindMenuItemsToComments();
    }

    bindMenuItemsToComments() {
      for (let i = 0; i < this.post.Comments.length; i++) {
        const comment = this.post.Comments[i];
        this.bindCommentEditItems(comment);
      }
    }

    showSocialPostOptions(comment) {
      this.dialogService.open(SocialPostOptionsDialogComponent, {
        context: {
          menuItems: comment ? comment.menuItems : this.editMenuItems,
          editing: this.editing
        }
      }).onClose.subscribe((result) => {
          if(!result) {
            return;
          } else if(result === "edit") {
            comment ? this.editComment(comment) : this.edit();
          } else if(result === "delete") {
            comment ? this.deleteComment(comment) : this.delete();
          }
      });
    }

    bindCommentEditItems(comment) {
      comment.menuItems = [];
      comment.editing = false;
      if (this.authService.isAdmin() || comment.UserId.toString() === this.currentUser.userId) {
        comment.menuItems.push({ title: 'Edit' });
        comment.menuItems.push({ title: 'Delete' });
      } else if ((this.post.GroupId && (this.group.CurrentUserAccessLevel === (GroupAccessLevel.Creator || GroupAccessLevel.Organizer))) ||
                  this.post.SchoolId && this.userIsCoachOfSchool) {
        comment.menuItems.push({ title: 'Delete' });
      }
    }

    setUrls(): void {
        const pattern = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/g;
        const content = this.post.Content;
        const matches = content.match(pattern);
        this.urls = matches || [];
    }

    toggleLike(post: Post) {
      if(!this.allowPosting) return;
      const wasPreviouslyLiked = post.IsLikedByCurrentUser;
      const newLikeValue = !wasPreviouslyLiked;

      this.socialService.toggleLike({
        Liked: newLikeValue,
        PostId: post.Id
      }).subscribe(() => {

      });

      post.IsLikedByCurrentUser = newLikeValue;
      if (wasPreviouslyLiked) {
        const like = post.Likes.filter(l => l.UserId === this.currentUser.id)[0];
        const index = post.Likes.indexOf(like);
        post.Likes.splice(index, 1);
      } else {
        post.Likes.push({UserId: this.currentUser.id, Id: -1, UserDisplayName: ''});
      }
    }

    toggleLikeComment(comment: PostComment) {
      const wasPreviouslyLiked = comment.IsLikedByCurrentUser;
      const newLikeValue = !wasPreviouslyLiked;

      this.socialService.toggleCommentLike({
        Liked: newLikeValue,
        PostId: comment.Id
      }).subscribe(() => {

      });

      comment.IsLikedByCurrentUser = newLikeValue;
      if (wasPreviouslyLiked) {
        const like = comment.Likes.filter(l => l.UserId === this.currentUser.id)[0];
        const index = comment.Likes.indexOf(like);
        comment.Likes.splice(index, 1);
      } else {
        comment.Likes.push({UserId: this.currentUser.id, Id: -1, UserDisplayName: ''});
      }
    }

    createPostComment() {
      this.socialService.createPostComment(this.newComment).subscribe((createdComment: PostComment) => {
        this.bindCommentEditItems(createdComment);
        this.post.Comments.unshift(createdComment);
      }, (err) => {
        this.toastService.error('Error posting comment');
        console.error(err);
      });
      this.newComment = new PostComment(this.post.Id);

    }

    openCommentBox() {
      if (this.allowPosting) {
        this.commentBox.nativeElement.focus();
      }
    }

    onAddCategoryFilter(category) {
      this.addCategoryFilter.emit(category);
    }

    onAddTagFilter(tag) {
      this.addTagFilter.emit(tag);
    }

    cancelEdit() {
      this.editing = false;
    }

    saveEditedPost() {
      this.saving = true;

      this.post.PostWorkouts = [];
      this.post.AttachedContent.forEach(content => {
        if (content.Type === AttachedContentType.Workout) {
          this.post.PostWorkouts.push(content);
        }
      });

      if (this.post.Content.length === 0 
        && this.post.AttachedContent.length === 0
        && this.post.Images.length === 0
        && this.post.ImageFiles.length === 0) {
        this.toastService.error('Post content cannot be empty');
        this.saving = false;
        return;
      }

      this.setUrls();


      this.editing = false;
      const imageFiles = [...this.post.ImageFiles];
      this.post.ImageFiles = [];
      this.onSavePostCalls.push(this.socialService.editPost(this.post));
      const uploadImageCalls = [];
      for(let file of imageFiles) {
        uploadImageCalls.push(this.socialService.addPostImage(this.post.Id, file));
      }
      this.getNumOfColumnsForImageGrid();
      forkJoin(this.onSavePostCalls).subscribe((results) => {
        forkJoin(uploadImageCalls).subscribe((imageIds) => {
          // add image ids from response
          for(var i = 0; i < imageFiles.length; i++) {
            const matchingImg = this.post.Images.find(img => img.Url === imageFiles[i].url)
            matchingImg.Id = imageIds[i];
          }
          this.toastService.success('Post saved');
          this.post.ImageFiles = [];
          this.originalPost = JSON.parse(JSON.stringify(this.post));
          this.saving = false;
        }, (err) => {
          console.error(err);
          this.post.ImageFiles = imageFiles;
          this.saving = false;
        });
        if(uploadImageCalls.length === 0) { this.saving = false; }
      }, e => {
        console.error(e);
        this.toastService.error('There was a problem saving the post');
        this.post = JSON.parse(JSON.stringify(this.originalPost));
        this.setUrls();
        this.saving = false;
      });
    }

    edit() {
      for(let image of this.post.Images) {
        image.isLoading = true;
      }
      this.editing = true;
    }

    delete() {
      this.dialogService.open(YesNoDialogComponent, { context: {
        text: 'Are you sure you want to delete the post?'
      }}).onClose.subscribe((result) => {
        if (!result) return;

        this.socialService.deletePost(this.post.Id).subscribe(() => {
          this.toastService.success('Post deleted');
          this.onDelete.emit();
        }, (e) => {
          console.error(e);
          this.toastService.error('There was a problem deleting the post');
        });
      });
    }

    editComment(comment) {
      comment.contentCopy = comment.Content;
      comment.editing = true;
    }

    saveEditedComment(comment) {
      if (comment.contentCopy.length === 0) {
        this.toastService.error('Comment cannot be empty');
        return;
      }

      const originalContent = comment.Content;

      comment.Content = comment.contentCopy;
      comment.editing = false;
      this.socialService.editComment(comment).subscribe(() => {
        this.toastService.success('Comment saved');
      }, (e) => {
        this.toastService.error('There was a problem saving the comment');
        console.error(e);
        comment.Content = originalContent;
      });
    }

    cancelEditComment(comment) {
      comment.editing = false;
    }

    deleteComment(comment) {
      this.dialogService.open(YesNoDialogComponent, { context: {
        text: 'Are you sure you want to delete the comment?'
      }}).onClose.subscribe((result) => {
        if (!result) return;

        this.socialService.deleteComment(comment.Id).subscribe(() => {
          this.post.Comments.splice(this.post.Comments.indexOf(comment), 1);
          this.toastService.success('Comment deleted');
        }, (e) => {
          console.error(e);
          this.toastService.error('There was a problem deleting the comment');
        });
      });
    }

    getProfileImage() {
      if(this.authService.isAthlete()) {
        this.dataService.getCurrentAthlete().subscribe(data => {
          this.athlete = data;
          this.dataService.getAthleteProfileData(this.athlete.AthleteID).subscribe(data => {
            this.profileImage = data["ImageURL"];
          });
        });
      } else {
        this.profileImage = this.userData.image;
      }

      if(!this.profileImage || this.profileImage?.toLowerCase() === '/images/profileplaceholder.png') {
        this.profileImage = undefined;
      }
    }

    removeContentFromPost(content) {
      this.post.AttachedContent.splice(this.post.AttachedContent.indexOf(content), 1);
      if(content.Type === AttachedContentType.Workout) {
        this.onSavePostCalls.push(this.socialService.deletePostWorkout(content.Id, this.post.Id));
      } else if(content.Type === AttachedContentType.PracticePlan) {

      }
    }

    postImageUploaded(event) {
      const files = event.target.files;
      let index = 0;
  
      const openCropper = (file) => {
        index++;
        const dialogContext: any = {
          image: file,
          cropOptions: this.postImageCropOptions
        };
    
        const dataURLtoFile = (dataurl, filename): File => {
    
          const arr = dataurl.split(',');
          const mime = arr[0].match(/:(.*?);/)[1];
          const bstr = atob(arr[1]);
          let n = bstr.length;
          const u8arr = new Uint8Array(n);
    
          while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
          }
    
          return new File([u8arr], filename, {type: mime});
        };
    
        const dialogRef = this.dialogService.open(FileCropperDialogComponent,
          { context: dialogContext, 
            closeOnBackdropClick: false,
            dialogClass: 'model-full'  });
    
        dialogRef.onClose.subscribe((imageData) => {
          if (files[index]) { openCropper(files[index]); }
          if (!imageData) { return; }
          file = dataURLtoFile(imageData, 'newProfilePic.png');
    
          const mimeType = files[0].type;
          if (mimeType.match(/image\/*/) == null) {
            this.toastService.error('Only images are supported.');
            return;
          }
    
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (_event) => {
            file.url = String(reader.result);
            const img = {
              Id: -1,
              Url: file.url
            }
            this.post.Images.push(img);
            this.post.ImageFiles.push(file);
          };
    
        });
      }
  
      openCropper(files[index]);
    }
  
    removePostImage(image) {
      if(image.Id === -1) {
        const matchingFile = this.post.ImageFiles.find(file => file.url === image.Url);
        const fileIndex = this.post.ImageFiles.indexOf(matchingFile);
        this.post.ImageFiles.splice(fileIndex, 1);
      } else {
        this.onSavePostCalls.push(this.socialService.deletePostImage(image.Id));
      }
      const matchingImg = this.post.Images.find(img => img.Url === image.Url);
      const imgIndex = this.post.Images.indexOf(matchingImg);
      this.post.Images.splice(imgIndex, 1);
  
    }

    openImage(selectedImage): void {
      const index = this.post.Images.indexOf(selectedImage);
      const album = [];
  
      for(let image of this.post.Images) {
        const img = {
          id: image.Id,
          thumbnail: image.Url,
          caption: '',
          src: image.Url
        };
        album.push(img);
      }
      this.dialogService.open(SocialImageLightboxComponent, {
        context: {
          userCanDelete: false,
          album,
          index: index
        }
      });
    }

    showSelectWorkoutDialog() {
      this.dialogService.open(SelectWorkoutDialogComponent, {
        context: {}
      }).onClose.subscribe((workout) => {
        if(workout) {
          workout.Type = AttachedContentType.Workout;
          this.post.AttachedContent.push(workout);
        }
      });
    }

    previewContent(content) {
      // if(content.Type === AttachedContentType.Workout) {
      //   this.dialogService.open(PreviewWorkoutDialogComponent, {
      //     context: { workout: content }
      //   }).onClose.subscribe(downloadOnClose => {
      //     if(downloadOnClose) {
      //       this.downloadPostContent(null, content);
      //     }
      //   });
      // } else if(content.Type === AttachedContentType.PracticePlan) {
      //   this.dialogService.open(PreviewPracticePlanDialogComponent, {
      //     context: { practicePlan: content }
      //   });
      // }
    }

    downloadPostContent(event, content) {
      if(event !== null) {
        event.stopPropagation();
        event.preventDefault();
      }
      if(content.Type === AttachedContentType.Workout) {
        this.socialService.downloadPostWorkout(content.Id).subscribe(() => {
          this.toastService.success('Workout downloaded into Default Folder');
        }, (e) => {
          console.error(e);
          this.toastService.error('There was a problem downloading the workout');
        });
      } else if(content.Type === AttachedContentType.PracticePlan) {

      }
    }

}
