import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { hubConnection, Connection, Proxy, Options } from 'signalr-no-jquery';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { AuthService } from './auth.service';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class SignalrService implements OnDestroy {
  public onMessageReceived: EventEmitter<any> = new EventEmitter();
  public unreadMessages: number = 0;
  public connection: Connection;
  private hubProxy: Proxy;

  constructor(private authService: AuthService, private messageService: MessageService) {
    this.connect();
  }

  connect() {
    this.connection = this.getNewConnection();
    this.hubProxy = this.connection.createHubProxy('MessageHub');

    this.hubProxy.on('receiveMessage', (message) => {
      message.Date = message.DateCreated;
      this.onMessageReceived.emit(message);
      this.unreadMessages++;
    });

    this.connection.reconnecting(() => {
      this.connection['qs'] = `access_token=${this.authService.getToken()}`;
    });

    this.connection.disconnected(() => {
      setTimeout(this.connection.start(), 10000);
    });

    this.connection.start()
      .fail(() => { setTimeout(this.connection.start(), 10000); });

    this.messageService.getNumberOfUnreadMessages().subscribe((result) => {
      this.unreadMessages = result;
    }, (e) => {
      console.error(e);
    });
  }

  getNewConnection() {
    const options: Options = {
      qs: `access_token=${this.authService.getToken()}`
    };

    return hubConnection(environment.baseUrl, options);
  }

  joinRoom(threadId) {
    if(this.hubProxy)
      this.hubProxy.invoke('JoinRoom', threadId);
  }

  leaveRoom(threadId) {
    if(this.hubProxy)
      this.hubProxy.invoke('LeaveRoom', threadId);
  }

  ngOnDestroy() {
    this.connection.stop();
  }
}
