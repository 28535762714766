<nb-card class="social-post-card" [nbSpinner]="saving">
  <nb-card-header class="post-origin" *ngIf="group && showOrigin && !post.PostAsGroup">
    <nb-icon icon="corner-down-right-outline"></nb-icon>
    {{ post.User.FullName }} posted in 
    <span (click)="navigateToGroup()">&nbsp;{{ group.Name }}</span>
  </nb-card-header>

  <nb-card-header>
    <div class="post-header">
      <nb-user *ngIf="!post.PostAsGroup"
        [name]="post.User.FullName"
        [picture]="post.User.ProfileImageURL"
        [title]="post.User.Title">
      </nb-user>
      <nb-user class="group-header" *ngIf="post.PostAsGroup"
        (click)="navigateToGroup()"
        [name]="post.GroupName"
        [picture]="post.GroupImageUrl">
      </nb-user>
      <div class="timestamp text-hint">
        {{post.DateCreated | amTimeAgo}}
      </div>
      <nb-icon class="edit-context-menu" *ngIf="editMenuItems.length > 0" 
                icon="more-horizontal-outline" (click)="showSocialPostOptions(null)"></nb-icon>
    </div>

    <div *ngIf="(post.Categories && post.Categories.length > 0) || (post.Tags && post.Tags.length > 0)" class="tags-and-categories">
      <span class="categories" *ngIf="post.Categories && post.Categories.length > 0">
          <nb-icon icon="archive-outline"></nb-icon>
          <span class="category-string" *ngFor="let category of post.Categories, let i = index" (click)="onAddCategoryFilter(category)">
            {{ category + (i < (post.Categories.length - 1) ? ', ' : '') }}
          </span>
      </span>
      <span class="tags" *ngIf="post.Tags && post.Tags && post.Tags.length > 0">
          <nb-icon icon="pricetags-outline"></nb-icon>
          <span class="category-string" *ngFor="let tag of post.Tags, let i = index" (click)="onAddTagFilter(tag)">
            {{ tag + (i < (post.Tags.length - 1) ? ', ' : '') }}
          </span>
      </span>
    </div>
  </nb-card-header>

  <nb-card-body *ngIf="!editing && urls && urls.length > 0">
    <embed-wrapper *ngFor="let url of urls" [url]="url"></embed-wrapper>
  </nb-card-body>
  <nb-card-body class="post-content" *ngIf="editing || post.Content">
    <div *ngIf="!editing" [innerHTML]="post.Content"></div>
    <textarea class="post-textarea" fullWidth nbInput placeholder="Enter post content" 
      [(ngModel)]="post.Content" *ngIf="editing"></textarea>
  </nb-card-body>

  <!-- Attached Content -->
  <nb-card-body class="social-post-attached-content" *ngIf="post.AttachedContent.length > 0">
    <div class="content-item" *ngFor="let content of post.AttachedContent" (click)="previewContent(content)">
        <nb-icon *ngIf="content.Type === AttachedContentType.Workout" icon="dumbbell" pack="rack"></nb-icon>
        <nb-icon *ngIf="content.Type === AttachedContentType.PracticePlan" icon="clipboard-outline"></nb-icon>
        <div class="content-title">{{content.Name}}</div>
        <nb-icon icon="cloud-download-outline" *ngIf="isCoach"
                   nbTooltip="Download" nbTooltipPlacement="bottom"
                   (click)="downloadPostContent($event, content)"></nb-icon>
        <nb-icon icon="close-circle-outline" *ngIf="editing"
                 (click)="removeContentFromPost(content)"
                 nbTooltip="Remove"
                 nbTooltipPlacement="bottom">
        </nb-icon>
    </div>
  </nb-card-body>

  <!-- Attached Images -->
  <nb-card-body class="social-post-attached-content" *ngIf="post.Images?.length > 0 && editing">
    <div class="attached-image" 
         *ngFor="let image of post.Images" 
         [nbSpinner]="image.isLoading || image.isLoading === undefined">
        <img [src]="image.Url" 
             (click)="openImage(image)" 
             [nbSpinner]="image.isLoading || image.isLoading === undefined"
             (load)="image.isLoading = false" />
        <div class="icon-wrapper">
            <nb-icon icon="close-circle-outline"
                    *ngIf="image.Url"
                     (click)="removePostImage(image)"
                     nbTooltip="Remove Image" 
                     nbTooltipPlacement="bottom"></nb-icon>
        </div>
    </div>
  </nb-card-body>
  <nb-card-body class="social-post-attached-content"
                *ngIf="post.Images?.length > 0 && !editing"
                style="padding: 0 !important">
    <div class="image-preview-grid" [style]="'grid-template-columns: repeat(' + imagePreviewGridColumns + ', 1fr)'">
      <div *ngFor="let image of post.Images; let index = index" 
          [nbSpinner]="image.isLoading || image.isLoading === undefined"
          [hidden]="index > 5" class="image">
      <img (click)="openImage(image)" 
            [src]="image.Url"
            [hidden]="image.isLoading || image.isLoading === undefined"
            (load)="image.isLoading = false" />
      <div *ngIf="index === 5 && this.post.Images.length > 6" class="more-images-message"
            (click)="openImage(post.Images[5])">
            + {{this.post.Images.length - 6}} more</div>
    </div>
     </div>
  </nb-card-body>

  <nb-card-body *ngIf="editing" class="post-editor-actions-wrapper">
    <div style="display: flex">
      <div class="post-editor-actions" *ngIf="isCoach">
        <div class="upload-img-btn">
          <nb-icon icon="image-outline" nbTooltip="Attach Image" nbTooltipPlacement="bottom">
          </nb-icon>
          <input type="file"
              nbInput 
              title=" "
              accept="image/*" 
              [multiple]="true" 
              #thumbnail
              (change)="postImageUploaded($event)"
              nbTooltip="Upload Image" nbTooltipPlacement="bottom">      
        </div>
        <nb-icon icon="dumbbell" pack="rack" (click)="showSelectWorkoutDialog()"></nb-icon>
        <!-- <nb-icon icon="clipboard-outline" nbTooltip="Attach Practice Plan" nbTooltipPlacement="bottom"></nb-icon>
        <nb-icon icon="calendar-outline" nbTooltip="Attach Event" nbTooltipPlacement="bottom"></nb-icon>
        <nb-icon icon="attach-outline" nbTooltip="Attach File" nbTooltipPlacement="bottom"></nb-icon>
        <span class="glyphter-text-format" nbTooltip="Format Text" nbTooltipPlacement="bottom"></span>
        <span class="glyphter-emoji" nbTooltip="Emojis" nbTooltipPlacement="bottom"></span> -->
      </div>
    </div>
    <div class="edit-controls">
      <button size="small" (click)="cancelEdit()" class="cancel" nbButton status="basic">Cancel</button>
      <button size="small" (click)="saveEditedPost()" nbButton status="primary">Save</button>
    </div>
  </nb-card-body>

  <nb-card-body class="post-actions">
    <button nbButton 
            ghost 
            size="small" 
            [status]="post.IsLikedByCurrentUser ? 'primary' : 'basic'"
            (click)="toggleLike(post)">
      <nb-icon pack="rack" icon="thumb-up-outline"></nb-icon>
      {{post.Likes.length}} Like<span *ngIf="post.Likes.length != 1">s</span>
    </button>
    <button nbButton 
            ghost 
            size="small" 
            status="basic"
            (click)="openCommentBox()">
      <nb-icon icon="message-circle-outline"></nb-icon>
      {{post.Comments.length}} Comment<span *ngIf="post.Comments.length != 1">s</span>
    </button>
  </nb-card-body>
  <nb-card-body *ngIf="allowPosting" style="display: flex; justify-content: space-between; align-items: center;">
    <nb-user onlyPicture="true" size="small" [picture]="profileImage"></nb-user>

    <input type="text" nbInput 
        #commentBox
        fullWidth
        (keyup.enter)="createPostComment()"
        size="small"
        placeholder="Write a Comment" 
        [(ngModel)]="newComment.Content" />
    <div style="margin-left: 10px">
      <button nbButton 
        size="small"
        status="primary"
        (click)="createPostComment()">Post</button>
    </div>
    
  </nb-card-body>
  <nb-card-body *ngIf="post.Comments.length > 0" class="post-comments">
    <ng-container *ngFor="let comment of post.Comments; let i = index">
      <div *ngIf="post.ShowAllComments || i < 3" class="post-comment">
        <div class="comment-header">
          <nb-user [name]="comment.User.FullName"
            [picture]="comment.User.ProfileImageURL"
            size="small">
          </nb-user>
          <div class="timestamp text-hint">
            {{comment.DateCreated | amTimeAgo}}
          </div>
          <nb-icon class="edit-context-menu" *ngIf="comment.menuItems?.length > 0" 
            icon="more-horizontal-outline" (click)="showSocialPostOptions(comment)"></nb-icon>
        </div>
        <div class="comment-content-wrapper">
          <div *ngIf="!comment.editing" class="comment-content" [innerHTML]="comment.Content"></div>
        </div>
        <div *ngIf="comment.editing" class="comment-edit-container">
          <input type="text" nbInput placeholder="Write a comment" [(ngModel)]="comment.contentCopy">
          <button size="small" (click)="cancelEditComment(comment)" class="cancel" nbButton status="basic">Cancel</button>
          <button size="small" (click)="saveEditedComment(comment)" nbButton status="primary">Save</button>
        </div>
        <button nbButton 
                size="small" 
                [status]="comment.IsLikedByCurrentUser ? 'primary' : 'basic'" 
                ghost 
                (click)="toggleLikeComment(comment)">
          <nb-icon pack="rack" icon="thumb-up-outline"></nb-icon>
          {{comment.Likes.length}} Like<span *ngIf="comment.Likes.length != 1">s</span>
        </button>
      </div>
    </ng-container>
    <div class="show-more-or-less-wrapper" *ngIf="post.Comments.length > 3">
      <button nbButton 
            ghost
            size="small"
            status="basic" 
            (click)="post.ShowAllComments = !post.ShowAllComments">
        Show {{post.ShowAllComments ? 'Less' : 'More'}} Comments
      </button>
    </div>
  </nb-card-body>
</nb-card>
