<nb-card>
    <nb-card-body class="body">
        <nb-icon *ngIf="showAdminActions" 
                class="action-button"
                icon="more-horizontal-outline" 
                (click)="showUserCardOptions(event, $event)" 
                nbTooltip="More" nbTooltipPlacement="bottom">
        </nb-icon>
        <img [src]="user.ProfileImageURL" />
        <div class="name">{{user.FullName}}</div>
        <div class="title">{{user.Title}}</div>
    </nb-card-body>
    
    <nb-card-body class="follow-section">
        <div class="followers">{{numOfFollowers}} Followers</div>
        <div class="follow-unfollow" *ngIf="user.UserId != userData.userId" (click)="toggleFollow()">
            <nb-icon [icon]="isFollowing ? 'minus-circle-outline' : 'plus-circle-outline'"></nb-icon>
            <span>{{ isFollowing ? 'Unfollow' : 'Follow' }}</span>
        </div>
    </nb-card-body>
</nb-card>