import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.scss']
})
export class YesNoDialogComponent implements OnInit {
  text: string;
  yesText = 'Yes';
  noText = 'No';
  yesStatus = 'primary';
  noStatus = 'danger';

  constructor(protected ref: NbDialogRef<YesNoDialogComponent>) { }

  ngOnInit(): void {
  }

  no() {
    this.ref.close(false);
  }

  yes() {
    this.ref.close(true);
  }

}
