import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { OrgSchoolSearch } from '../../../@core/data/org-school';
import { SocialService } from '../../../services/social.service';

@Component({
  selector: 'ngx-school-autocomplete',
  templateUrl: './school-autocomplete.component.html',
  styleUrls: ['./school-autocomplete.component.scss']
})
export class SchoolAutocompleteComponent implements OnInit {
  @ViewChild('autoInput') input;
  selected: OrgSchoolSearch;
  filtered$: Observable<OrgSchoolSearch[]>;
  formControl = new FormControl();
  @Output() onSelection = new EventEmitter<OrgSchoolSearch>();
  @Input() initialSelection: any;

  timeout: any;
  debounceTime: number = 500;

  constructor(private socialService: SocialService) { }

  ngOnInit(): void {
    this.selected = this.initialSelection;
    this.formControl.valueChanges.subscribe((value) => {
      const later = () => {
        if (typeof(value) === 'string')
          this.filtered$ = this.getFiltered(value);
      };
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(later, this.debounceTime);
    });
  }

  getFiltered(value: string): Observable<OrgSchoolSearch[]> {
    if (!value || value.length < 3) {
      return new Observable<OrgSchoolSearch[]>();
    }
    const retVal = new Observable<OrgSchoolSearch[]>((observer) => {
      this.socialService.searchSchools(value).subscribe((result) => {
        observer.next(result.slice());
      }, (e) => {
        observer.error(e);
      });
    });
    return retVal;
  }

  onChange() {
    const value = this.input.nativeElement.value;
    this.filtered$ = this.getFiltered(value);
  }

  onSelectionChange($event) {
  }

  onOptionClick(option) {
    this.selected = option;
    this.onSelection.emit(option);
  }

  viewHandle(value) {
    return value.name;
  }

}
