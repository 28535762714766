import { NbMenuItem } from '@nebular/theme';

export const HEADER_MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'ACTIVITY FEED',
    link: '/activity-feed',
  },
  {
    title: 'SYSTEMS',
    link: '/courses',
  },
  {
    title: 'LIBRARY',
    link: '/search',
  },
  {
    title: 'GROUPS',
    link: '/groups',
  },
  {
    title: 'FORUMS',
    link: '/forums',
  }

];
