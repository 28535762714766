import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Post, PostComment } from '../@core/data/social-types';
import { User } from '../@core/data/users';
import { HistoryType, UserHistory } from '../@core/data/user-history';
import { Group, GroupAnnouncement, GroupDocument, GroupEvent, GroupEventAttendance, GroupImage, GroupPoll, GroupPollNomination, GroupPollVote, GroupUser } from '../@core/data/group';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  private baseUrl: string = environment.baseUrl + '/api/GroupApi';

  constructor(private http: HttpClient) { }

  public getGroup(id: Number): Observable<Group> {
    return this.http.get<Group>(this.baseUrl + '/GetGroup/' + id);
  }

  public createOrUpdateGroup(group: Group): Observable<Group> {
    if (group.Id > 0) {
      return this.http.put<Group>(this.baseUrl + '/Update', group);
    }
    return this.http.post<Group>(this.baseUrl + '/Create', group);
  }

  public updateImage(imageData, groupId: Number): Observable<any> {
    const data = new FormData();
    data.append('image', imageData);
    return this.http.post(this.baseUrl + '/UpdateImage?id=' + groupId, data);
  }

  public saveGroupBanner(imageData, groupId: Number): Observable<any> {
    const data = new FormData();
    data.append('image', imageData);
    return this.http.post(this.baseUrl + '/SaveGroupBanner?id=' + groupId, data);
  }

  public getGroups(): Observable<Group[]> {
    return this.http.get<Group[]>(this.baseUrl + '/GetGroups');
  }

  public getPendingInviteGroups(): Observable<Group[]> {
    return this.http.get<Group[]>(this.baseUrl + '/GetPendingInviteGroups');
  }

  public requestMembership(groupId: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/RequestMembership/' + groupId);
  }

  public getMembershipRequests(groupId: number): Observable<GroupUser[]> {
    return this.http.get<GroupUser[]>(this.baseUrl + `/GetMembershipRequests?groupId=${groupId}`);
  }

  public acceptMembershipRequest(groupUserId): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/AcceptMembershipRequest?groupUserId=${groupUserId}`);
  }

  public declineMembershipRequest(groupUserId): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/DeclineMembershipRequest?groupUserId=${groupUserId}`);
  }

  public cancelMembershipRequest(groupUserId): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/CancelMembershipRequest?groupUserId=${groupUserId}`);
  }

  public joinGroup(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/JoinGroup/' + id);
  }

  public leaveGroup(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/LeaveGroup/' + id);
  }

  public inviteUsersToGroup(data): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/InviteUsers', data);
  }

  public updateUsersPermissions(data): Observable<any> {
    return this.http.put<any>(this.baseUrl + '/UpdateUsersPermissions', data);
  }

  public acceptInvite(groupInviteId): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/AcceptInvite/' + groupInviteId);
  }

  public declineInvite(groupInviteId): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/DeclineInvite/' + groupInviteId);
  }

  public addImagesToGroup(groupId: Number, images: any[]): Observable<GroupImage[]> {
    const data = new FormData();
    for (let i = 0; i < images.length; i++) {
      data.append('image' + i, images[i]);
    }
    return this.http.post<GroupImage[]>(this.baseUrl + '/AddImages?id=' + groupId, data);
  }

  public removeImageFromGroup(id: Number, groupId: Number) {
    return this.http.delete<any>(this.baseUrl + `/RemoveImageFromGroup?id=${id}&groupId=${groupId}`);
  }

  public addDocumentsToGroup(groupId: Number, files: any[]): Observable<GroupDocument[]> {
    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
      data.append('file' + i, files[i]);
    }
    return this.http.post<GroupDocument[]>(this.baseUrl + '/AddDocuments?id=' + groupId, data);
  }

  public removeDocumentFromGroup(id: Number, groupId: Number) {
    return this.http.delete<any>(this.baseUrl + `/RemoveDocumentFromGroup?id=${id}&groupId=${groupId}`);
  }

  public deleteGroup(id: Number) {
    return this.http.delete<any>(this.baseUrl + '/Delete/' + id);
  }

  public removeUserFromGroup(groupUserId: number, groupId: number) {
    return this.http.get<any>(this.baseUrl + `/RemoveUser?groupId=${groupId}&groupUserId=${groupUserId}`);
  }

  // Announcements
  public addAnnouncement(data) {
    return this.http.post<number>(this.baseUrl + '/AddAnnouncement', data);
  }

  public getAnnouncements(groupId: Number): Observable<GroupAnnouncement[]> {
    return this.http.get<GroupAnnouncement[]>(this.baseUrl + `/GetAnnouncements?groupId=${groupId}`);
  }

  public deleteAnnouncement(id: Number) {
    return this.http.delete<any>(this.baseUrl + '/DeleteAnnouncement/' + id)
  }

  public updateAnnouncement(announcement: GroupAnnouncement) {
    return this.http.put<GroupAnnouncement>(this.baseUrl + '/UpdateAnnouncement', announcement);
  }

  // Events
  public addEvent(data) {
    return this.http.post<GroupEvent>(this.baseUrl + '/AddEvent', data);
  }

  public getEvents(groupId: Number): Observable<GroupEvent[]> {
    return this.http.get<GroupEvent[]>(this.baseUrl + `/GetEvents?groupId=${groupId}`);
  }

  public deleteEvent(id: Number) {
    return this.http.delete<any>(this.baseUrl + '/DeleteEvent/' + id)
  }

  public updateEvent(groupEvent: GroupEvent) {
    return this.http.put<GroupEvent>(this.baseUrl + '/UpdateEvent', groupEvent);
  }

  // Event Attendance
  public addEventAttendance(attendance: GroupEventAttendance) {
    return this.http.post<GroupEventAttendance>(this.baseUrl + '/AddEventAttendance', attendance);
  }

  public updateEventAttendance(attendance: GroupEventAttendance) {
    return this.http.put<GroupEventAttendance>(this.baseUrl + '/UpdateEventAttendance', attendance);
  }

  public getEventAttendancesForUser(userId: Number): Observable<GroupEventAttendance[]> {
    return this.http.get<GroupEventAttendance[]>(this.baseUrl + `/GetEventAttendancesForUser?userId=${userId}`);
  }

  public getEventAttendances(eventId: Number): Observable<GroupEventAttendance[]> {
    return this.http.get<GroupEventAttendance[]>(this.baseUrl + `/GetEventAttendances?eventId=${eventId}`);
  }
  
  // Polls
  public addPoll(poll) {
    return this.http.post<GroupPoll>(this.baseUrl + '/AddPoll', poll);
  }

  public getPolls(groupId: Number): Observable<GroupPoll[]> {
    return this.http.get<GroupPoll[]>(this.baseUrl + `/GetPolls?groupId=${groupId}`);
  }

  public deletePoll(id: Number) {
    return this.http.delete<any>(this.baseUrl + '/DeletePoll/' + id)
  }

  public updatePoll(poll: GroupPoll) {
    return this.http.put<GroupPoll>(this.baseUrl + '/UpdatePoll', poll);
  }

   // Poll Nominations
   public addPollNomination(nomination) {
    return this.http.post<GroupPollNomination>(this.baseUrl + '/AddPollNomination', nomination);
  }

  public getPollNomination(nominationId: Number): Observable<GroupPollNomination> {
    return this.http.get<GroupPollNomination>(this.baseUrl + `/GetPollNomination?nominationId=${nominationId}`);
  }

  public getPollNominations(groupPollId: Number): Observable<GroupPollNomination[]> {
    return this.http.get<GroupPollNomination[]>(this.baseUrl + `/GetPollNominations?groupPollId=${groupPollId}`);
  }

  public deletePollNomination(nominationId: Number) {
    return this.http.delete<any>(this.baseUrl + '/DeletePollNomination/' + nominationId)
  }

  public updatePollNomination(nomination: GroupPollNomination) {
    return this.http.put<GroupPollNomination>(this.baseUrl + '/UpdatePollNomination', nomination);
  }

  public savePollNominationImage(imageData, nominationId: Number): Observable<any> {
    const data = new FormData();
    data.append('image', imageData);
    return this.http.post(this.baseUrl + '/SavePollNominationImage?id=' + nominationId, data);
  }

  // Poll Votes
  public addPollVote(vote) {
    return this.http.post<GroupPollVote>(this.baseUrl + '/AddPollVote', vote);
  }

  public getPollVotes(groupPollId: Number): Observable<GroupPollVote[]> {
    return this.http.get<GroupPollVote[]>(this.baseUrl + `/GetPollVotes?groupPollId=${groupPollId}`);
  }

  public deletePollVote(voteId: Number) {
    return this.http.delete<any>(this.baseUrl + '/DeletePollVote/' + voteId)
  }

  public updatePollVote(vote: GroupPollVote) {
    return this.http.put<GroupPollVote>(this.baseUrl + '/UpdatePollVote', vote);
  }
}
