<div class="social-image-lightbox-wrapper">
    <div class="image-section" [nbSpinner]="loadingImage" (mouseenter)="onMouseEnterImage()" (mouseleave)="onMouseLeaveImage()">
        <div *ngIf="showChevrons" class="chevron-container left"><nb-icon (click)="prevImage()" icon="chevron-left-outline"></nb-icon></div>
        <img [hidden]="loadingImage" [src]="album[index].thumbnail" (load)="loadingImage = false">
        <div *ngIf="showChevrons" class="chevron-container right"><nb-icon (click)="nextImage()" icon="chevron-right-outline"></nb-icon></div>
    </div>
    <!-- <div class="social-section">
        <p>SOCIAL CONTENT HERE</p>
    </div> -->
    <div class="actions">
        <nb-icon (click)="removeImage()" icon="trash-outline" *ngIf="userCanDelete"></nb-icon>
        <nb-icon (click)="closeDialog()" icon="close-outline" style="transform: scale(1.25);"></nb-icon>
    </div>
</div>