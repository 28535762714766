<nb-card>
  <nb-card-header>Error</nb-card-header>
  <nb-card-body>
    <div class="error" *ngFor="let error of errors">{{error}}</div>
  </nb-card-body>
  <nb-card-footer>
    <button class="cancel" nbButton status="danger" (click)="ok()">Ok</button>
  </nb-card-footer>
</nb-card>
    
